import React from 'react';
import BreadCrumbComponent from '../../../../components/common/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Col, Nav, Row, Tab } from '@themesberg/react-bootstrap';
import { useSelector } from 'react-redux';
import AddVendorUser from './AddVendorUser';
import AddAccountsUser from './AddAccountsUser';
import AddCMUser from './AddCMUser';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import useGetCompanyAndCategory from '../../custom-hooks/useGetCompanyAndCategory';

const AddSchemeUsers = () => {
  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );
  const isAccountManager = useSelector(
    (state) => state?.user?.preferences?.isAccountManager || false
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );

  const { t } = useTranslation();

  const breadCrumbItems = arrayToBreadCrumbs([['Users'], ['Add Scheme Users']]);

  useGetCompanyAndCategory();

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbComponent items={breadCrumbItems} />
          <h4>{'Add New User'}</h4>
          <p className="mb-0">
            {
              'You can add a new Vendor / Account / Brand Manager scheme user here'
            }
          </p>
        </div>
      </div>
      {isAccountManager && (
        <Tab.Container defaultActiveKey="BM">
          <Row>
            <Col lg={12}>
              <Nav className="nav-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="BM" className="mb-sm-3 mb-md-0">
                    {t('Add PMG')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={12}>
              <Tab.Pane eventKey="BM" className="py-4">
                <AddCMUser />
              </Tab.Pane>
            </Col>
          </Row>
        </Tab.Container>
      )}
      {isBrandManager && (
        <Tab.Container defaultActiveKey="vendor">
          <Row>
            <Col lg={12}>
              <Nav className="nav-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="vendor" className="mb-sm-3 mb-md-0">
                    {t('Add Brand Vendor')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={12}>
              <Tab.Pane eventKey="vendor" className="py-4">
                <AddVendorUser />
              </Tab.Pane>
            </Col>
          </Row>
        </Tab.Container>
      )}
      {isAdmin && (
        <Tab.Container defaultActiveKey="vendor">
          <Row>
            <Col lg={12}>
              <Nav className="nav-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="vendor" className="mb-sm-3 mb-md-0">
                    {t('Add Brand Vendor')}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="BM" className="mb-sm-3 mb-md-0">
                    {t('Add PMG')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="accounts" className="mb-sm-3 mb-md-0">
                    {t('Add Accounts Manager')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={12}>
              <Tab.Content>
                <Tab.Pane eventKey="vendor" className="py-4">
                  <AddVendorUser />
                </Tab.Pane>
                <Tab.Pane eventKey="BM" className="py-4">
                  <AddCMUser />
                </Tab.Pane>
                <Tab.Pane eventKey="accounts" className="py-4">
                  <AddAccountsUser />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
    </>
  );
};
export default AddSchemeUsers;
