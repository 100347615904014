import { Button, Modal } from '@themesberg/react-bootstrap';
import React from 'react';
import { generateRandomId } from '../../../../utils/string';
import { useTranslation } from 'react-i18next';
import {
  returnUserReadableDate,
  returnUserReadableDateTime,
} from '../../../../utils/datetime';

const PaymentDetailsModal = ({ showModal, closeModal, data }) => {
  const { t } = useTranslation();

  console.log(returnUserReadableDate(data?.payment[0]?.paymentDateAndTime));

  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title className="h6">{t(`Payments Summary`)}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-evenly pt-1">
          <div>
            <span className="fw-bold">Method : </span>{' '}
            {data?.payment?.[0]?.method || 'method'}
          </div>
          <div>
            <span className="fw-bold">Mode : </span>{' '}
            {data?.payment?.map((each) => each?.paymentMode) || 'mode'}
          </div>
          <div>
            <span className="fw-bold">Type : </span>{' '}
            {data?.payment?.map((each) => each?.paymentType) || 'mode'}
          </div>
          <div>
            <span className="fw-bold">Amount : </span>{' '}
            {data?.payment?.[0]?.billAmount || 'amount'}
          </div>
        </div>
        <div className="d-flex justify-content-evenly gap-3 mx-3 mt-2">
          <div
            style={{
              backgroundColor: '#EAEDF2',
              padding: '15px',
              borderRadius: '5px',
              lineHeight: '20px',
              width: 'fit-content',
              maxWidth: '397px',
            }}
          >
            <h6>Order Information</h6>
            <div>
              <div>
                <strong>Order Number:</strong>
                {data?.orderNo}
              </div>
              <div>
                <strong>Order Date:</strong>{' '}
                {returnUserReadableDate(data?.createdAt)}
              </div>
              <div>
                <strong>Order Time:</strong>{' '}
                {returnUserReadableDateTime(data?.createdAt)?.split(',')[2]}
              </div>
              <div>
                <strong>Customer Name:</strong> {data?.customer?.firstName}
              </div>
              <div>
                <strong>Shipping Address:</strong>{' '}
                {data?.shippingAddress?.shippingAddress1 +
                  ', ' +
                  data?.shippingAddress?.shippingCity +
                  ', ' +
                  data?.shippingAddress?.shippingState +
                  ', ' +
                  data?.shippingAddress?.shippingPincode}
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#EAEDF2',
              padding: '15px',
              borderRadius: '5px',
              lineHeight: '20px',
              width: 'fit-content',
              maxWidth: '397px',
            }}
          >
            <h6>Payment Information</h6>
            <div>
              <div>
                <strong>Transaction Id:</strong>
                {data?.payment[0]?.trancationId}
              </div>
              <div>
                <strong>Payment Status:</strong>
                {data?.payment[0]?.paymentStatus}
              </div>
              <div>
                <strong>Payment Date:</strong>{' '}
                {data?.payment[0]?.paymentDateAndTime?.split(' ')[0]}
              </div>
              <div>
                <strong>Payment Time:</strong>{' '}
                {data?.payment[0]?.paymentDateAndTime?.split(' ')[1]}
              </div>
              <div>
                <strong>Billing Address:</strong>{' '}
                {data?.shippingAddress?.billingAddress1 +
                  ', ' +
                  data?.shippingAddress?.billingCity +
                  ', ' +
                  data?.shippingAddress?.billingState +
                  ', ' +
                  data?.shippingAddress?.billingPincode}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            // backgroundColor: '#EAEDF2',
            padding: '15px',
            borderRadius: '5px',
            maxWidth: '829px',
            margin: '0 auto',
          }}
        >
          <h6
            style={{
              //   marginBottom: '15px',
              borderBottom: '2px solid #d9d9d9',
              paddingBottom: '10px',
            }}
          >
            Product Information
          </h6>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', paddingBottom: '10px' }}>
                  Product Name
                </th>
                <th style={{ textAlign: 'right', paddingBottom: '10px' }}>
                  Quantity
                </th>
                <th style={{ textAlign: 'right', paddingBottom: '10px' }}>
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.items?.map((item, index) => (
                <tr key={index}>
                  <td style={{ padding: '5px 0' }}>{item?.productTitle}</td>
                  <td style={{ textAlign: 'right', padding: '5px 0' }}>
                    {item?.quantity}
                  </td>
                  <td style={{ textAlign: 'right', padding: '5px 0' }}>{`₹${
                    item?.vsPrice || item?.mrp || item?.orderAmount
                  }`}</td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan="2"
                  style={{
                    padding: '10px 0',
                    fontWeight: 'bold',
                    borderTop: '2px solid #d9d9d9',
                  }}
                >
                  Sub Total
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    padding: '10px 0',
                    fontWeight: 'bold',
                    borderTop: '2px solid #d9d9d9',
                  }}
                >
                  {data?.items?.reduce(
                    (total, items) =>
                      total +
                      (items.vsPrice || items.mrp || items.orderAmount) *
                        items.quantity,
                    0
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              //   marginTop: '20px',
            }}
          >
            <div style={{ width: '48%' }}>
              <h6
                style={{
                  borderBottom: '1px solid #d9d9d9',
                  //   paddingBottom: '5px',
                }}
              >
                Discount & Offers
              </h6>
              <div>
                Coupon Amount: {`₹${data?.payment[0]?.couponAmount || ''}`}
              </div>
              <div>
                Coupon Code:{' '}
                <spam className="text-end">
                  {' '}
                  {data?.payment[0]?.couponCode || ''}
                </spam>
              </div>
              <div>PG Offer: {`₹${data?.payment[0]?.pgDiscount}` || ''}</div>
            </div>
            <div style={{ width: '48%' }}>
              <h6
                style={{
                  borderBottom: '1px solid #d9d9d9',
                  //   paddingBottom: '5px',
                }}
              >
                Taxes & Fees
              </h6>
              <div>CGST : {`₹${data?.cgst}` || ''}</div>
              <div>SGST : {`₹${data?.sgst}` || ''}</div>
              <div>
                Shipping Fee: {`₹${data?.payment[0]?.shippingAmount}` || ''}
              </div>
            </div>
          </div>

          <div style={{ marginTop: '20px' }}>
            <h6
              style={{
                borderBottom: '1px solid #d9d9d9',
                // paddingBottom: '5px',
              }}
            >
              Payment Allocation
            </h6>
            {data?.payment?.map((each, index) => (
              <div key={index}>
                {each?.paymentType}: {`₹${each?.billAmount}` || '0.00'}
              </div>
            ))}
          </div>

          <div
            style={{
              marginTop: '20px',
              fontWeight: 'bold',
              borderTop: '2px solid #d9d9d9',
              paddingTop: '10px',
              textAlign: 'right',
            }}
          >
            <div>Total Amount: {`₹${data?.orderAmount}` || ''}</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentDetailsModal;
