import React from 'react';
import { returnUserReadableDateTime } from '../../../../utils/datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faBox,
  faCancel,
  faCheck,
  faCheckDouble,
  faCircleCheck,
  faCircleXmark,
  faCross,
  faExchange,
  faFileInvoice,
  faHandHolding,
  faHourglassStart,
  faMoneyBillTransfer,
  faPeopleCarry,
  faStore,
  faTentArrowTurnLeft,
  faTimesCircle,
  faTruckFast,
  faTruckRampBox,
  faUserPlus,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';

const Timeline = ({ events, rejectedHistory }) => {
  const actionLog = events || [];

  const historyObj = {
    Assigned: { title: 'Assigned', icon: faUserPlus },
    Accepted: { title: 'Accepted', icon: faCheck },
    'Awaiting Invoice': { title: 'Awaiting Invoice', icon: faHourglassStart },
    Invoiced: { title: 'Invoiced', icon: faFileInvoice },
    'Ready For Pickup': { title: 'Ready For Pickup', icon: faBox },
    'Picked Up': { title: 'Picked Up', icon: faTruckFast },
    'Out For Delivery': { title: 'Out For Delivery', icon: faPeopleCarry },
    Delivered: { title: 'Delivered', icon: faTruckRampBox },
    Rejected: { title: 'Rejected', icon: faTimesCircle },
    'Auto Rejected': { title: 'Auto Rejected', icon: faBan },
    Requested: { title: 'Requested', icon: faTentArrowTurnLeft },
    Accepted: { title: 'Accepted', icon: faCheck },
    'Picked Up': { title: 'Picked Up', icon: faTruckFast },
    Received: { title: 'Received', icon: faStore },
    'QC Passed': { title: 'QC Passed', icon: faCircleCheck },
    'Refund Initiated': {
      title: 'Refund Initiated',
      icon: faMoneyBillTransfer,
    },
    Refunded: { title: 'Refunded', icon: faCheckDouble },
    Cancelled: { title: 'Cancelled', icon: faXmarkCircle },
  };

  const getHistoryArray = (rejectedHistory) => {
    const show = [];
    rejectedHistory.map((each) => {
      if (historyObj[each?.action]) {
        show.push(historyObj[each?.action]);
      } else {
        show.push({ title: each?.action, icon: faExchange });
      }
    });

    return show;
  };

  const flowDefinitions = {
    default: [
      { title: 'Assigned', icon: faUserPlus },
      { title: 'Accepted', icon: faCheck },
      { title: 'Awaiting Invoice', icon: faHourglassStart },
      { title: 'Invoiced', icon: faFileInvoice },
      { title: 'Ready For Pickup', icon: faBox },
      { title: 'Picked Up', icon: faTruckFast },
      { title: 'Out For Delivery', icon: faPeopleCarry },
      { title: 'Delivered', icon: faTruckRampBox },
    ],
    rejected: [
      { title: 'Assigned', icon: faUserPlus },
      { title: 'Rejected', icon: faTimesCircle },
    ],
    acceptedRejected: [
      { title: 'Assigned', icon: faUserPlus },
      { title: 'Accepted', icon: faCheck },
      { title: 'Rejected', icon: faTimesCircle },
    ],
    autoRejected: [
      { title: 'Assigned', icon: faUserPlus },
      { title: 'Auto Rejected', icon: faBan },
    ],
    qcFailed: [
      { title: 'Assigned', icon: faUserPlus },
      { title: 'Accepted', icon: faCheck },
      { title: 'QC Failed', icon: faCircleXmark },
    ],
    returns: [
      { title: 'Requested', icon: faTentArrowTurnLeft },
      { title: 'Accepted', icon: faCheck },
      { title: 'Picked Up', icon: faTruckFast },
      { title: 'Received', icon: faStore },
      { title: 'QC Passed', icon: faCircleCheck },
      { title: 'Refund Initiated', icon: faMoneyBillTransfer },
      { title: 'Refunded', icon: faCheckDouble },
    ],
  };

  const determineFlow = (actionLog) => {
    if (actionLog?.some((event) => event?.action === 'Auto Rejected')) {
      return 'autoRejected';
    }
    if (actionLog?.some((event) => event.action === 'Rejected')) {
      if (actionLog?.some((event) => event.action === 'Accepted')) {
        return 'acceptedRejected';
      }
      return 'rejected';
    }
    if (actionLog?.some((event) => event.action === 'QC Failed')) {
      return 'qcFailed';
    }
    if (actionLog?.some((event) => event.action === 'Requested')) {
      return 'returns';
    }
    return 'default';
  };

  let currentFlow = determineFlow(actionLog);

  const arrayList = rejectedHistory
    ? getHistoryArray(actionLog)
    : flowDefinitions[currentFlow];

  const steps = arrayList.map((step) => ({
    ...step,
    date: '',
    completed: rejectedHistory ? true : false,
    user: null,
    remarks: null,
  }));

  for (let i = 0; i < actionLog?.length; i++) {
    const currentAction = actionLog[i];
    const stepIndex = steps.findIndex(
      (step) => step.title === currentAction.action
    );

    if (stepIndex !== -1) {
      steps[stepIndex].date = currentAction?.time?.iso;
      steps[stepIndex].completed = true;
      steps[stepIndex].user = currentAction?.user;
      steps[stepIndex].remarks = currentAction?.remarks;
      if (currentAction?.icon) {
        steps[stepIndex].icon = currentAction?.icon;
      }
    }
  }

  const remarksArray = ['Requested', 'Rejected', 'QC Failed'];

  const renderToolTip = (props, user, remarks, title) => (
    <Tooltip id="button-tooltip" {...props}>
      User: {user ? user : 'System'} <br />
      {remarksArray.includes(title) && <>Remarks: {remarks ? remarks : ''}</>}
    </Tooltip>
  );
  return (
    <div
      className="my-2"
      style={{
        display: 'flex',
        overflowX: 'scroll',
        alignItems: 'center',
      }}
    >
      {steps?.map((step, index) => (
        <React.Fragment key={index}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 'fit-content',
              // marginTop: step?.completed ? '40px' : '10px',
              fontSize: 'clamp(10px,12px,12px)',
              color: step?.completed ? 'black' : 'lightgray',
            }}
          >
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) =>
                renderToolTip(props, step?.user, step?.remarks, step?.title)
              }
            >
              <div className="fs-4">
                <FontAwesomeIcon icon={step?.icon} />
              </div>
            </OverlayTrigger>
            <div className="fw-bold px-2">{step?.title}</div>
            <div style={{ fontSize: 'clamp(10px,10px,10px)' }}>
              {step?.date &&
                returnUserReadableDateTime(step?.date).split(',')[0]}
            </div>
            <div style={{ fontSize: 'clamp(10px,10px,10px)' }}>
              {step?.date &&
                returnUserReadableDateTime(step?.date).split(',')[2]}
            </div>
          </div>
          {index < steps.length - 1 && (
            <div
              style={{
                height: '2px',
                width: '70px',
                backgroundColor:
                  step?.completed && steps[index + 1]?.completed
                    ? 'black'
                    : 'lightgray',
                // marginTop: event?.completed ? "-40px" : "-50px",
                // alignSelf: "center",
              }}
            ></div>
          )}
        </React.Fragment>
      ))}
      <style>{`::-webkit-scrollbar { display: none; }`}</style>
    </div>
  );
};

export default Timeline;
