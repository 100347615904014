import React from "react";
import { Badge } from "@themesberg/react-bootstrap";
import { returnUserReadableDateTime } from "../datetime";
import { getTripBadgeStatus } from "../trips";

export const infoTable = () => {
  return [
    {
      dataField: "user",
      text: "User",
    },
    {
      dataField: "time",
      text: "Time",
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <Badge bg={getTripBadgeStatus(cell)} className="badge-md">
              {cell}
            </Badge>
          </div>
        );
      },
    },
    {
      dataField: "actionType",
      text: "actionType",
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <Badge bg={getTripBadgeStatus(cell)} className="badge-md">
              {cell}
            </Badge>
          </div>
        );
      },
    },
    {
      dataField: "reason",
      text: "Reason/Remarks",
    },
  ];
};
