import {
  faUserTie,
  faListUl,
  faPlus,
  faHourglass,
  faCheckDouble,
  faBan,
  faLock,
  faTags,
  faAdd,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/img/brand/vijay-sales-logo.svg';
import { Routes as AppRoutes } from '../../router/routes';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';

const navigationConfig = {
  Dashboard: {
    title: 'Dashboard',
    link: AppRoutes.SMS.Target.DraftSchemes.path,
    image: logo,
  },
  Schemes: {
    title: 'Scheme',
    eventKey: 'scheme/',
    icon: faTags,
    children: [
      {
        title: 'Add a Scheme',
        icon: faPlus,
        link: AppRoutes.SMS.Target.AddScheme.path,
      },
      {
        title: 'Draft',
        icon: faHourglass,
        link: AppRoutes.SMS.Target.DraftSchemes.path,
      },
      {
        title: 'Active',
        icon: faCheckDouble,
        link: AppRoutes.SMS.Target.ActiveSchemes.path,
      },
      {
        title: 'Cancelled',
        icon: faBan,
        link: AppRoutes.SMS.Target.RejectedSchemes.path,
      },
      {
        title: 'Closed',
        icon: faLock,
        link: AppRoutes.SMS.Target.ClosedSchemes.path,
      },
    ],
  },
  Users: {
    title: 'Users',
    eventKey: 'addUsers/',
    icon: faUserTie,
    children: [
      {
        title: 'Add a User',
        icon: faUserTie,
        link: AppRoutes.SMS.Target.AddUser.path,
      },
      {
        title: 'User List',
        icon: faListUl,
        link: AppRoutes.SMS.Target.SMSUserList.path,
      },
    ],
  },
  Products: {
    title: 'Products',
    icon: faProductHunt,
    eventKey: 'products/',
    children: [
      {
        title: 'Add Product',
        icon: faAdd,
        link: AppRoutes.Admin.Product.Add.path,
      },
    ],
  },
};

export default navigationConfig;
