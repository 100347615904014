import {
  RESET_STORE,
  SET_COMPANIES_AND_CATEGORIES_DATA_IN_STORE,
  SET_MATCHING_PRODUCTS_IN_STORE,
  SET_PRODUCTSLIST_DROPDOWN_IN_STORE,
  SET_SUB_CATEGORIES_DATA_IN_STORE,
} from '../actions/types';
import { convertArrayToObj } from '../utils/json';

const INITIAL_STATE = {
  companies: {},
  categories: {},
  productsList: {},
  subCategories: {},
  productsListDropdown: {},
};
const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMPANIES_AND_CATEGORIES_DATA_IN_STORE: {
      const { companies, categories } = action.data;
      return {
        ...state,
        companies: convertArrayToObj(companies, 'objectId'),
        categories: convertArrayToObj(categories, 'objectId'),
      };
    }
    case SET_SUB_CATEGORIES_DATA_IN_STORE: {
      const { subCategories } = action.data;
      return {
        ...state,
        subCategories: convertArrayToObj(subCategories, 'objectId'),
      };
    }
    case SET_MATCHING_PRODUCTS_IN_STORE: {
      return {
        ...state,
        productsList: convertArrayToObj(action.data, 'objectId'),
      };
    }
    case SET_PRODUCTSLIST_DROPDOWN_IN_STORE: {
      return {
        ...state,
        productsListDropdown: action.data,
      };
    }
    case RESET_STORE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default productReducer;
