export const editAccountsUserSchema = [
  [
    {
      title: "Name",
      id: "name",
      type: "text",
      required: true,
    },
    {
      title: "Phone Number",
      id: "phoneNumber",
      type: "number",
      required: true,
    },
    {
      title: "Email Id",
      id: "emailId",
      type: "email",
      required: true,
    },
    {
      title: "Update user",
      type: "button",
    },
  ],
];

export const editBrandManagerUserSchema = [
  [
    {
      title: "Name",
      id: "name",
      type: "text",
      required: true,
    },
    {
      title: "Phone Number",
      id: "phoneNumber",
      type: "number",
      required: true,
    },
    {
      title: "Email Id",
      id: "emailId",
      type: "email",
      required: true,
    },
    {
      title: "Company",
      id: "company",
      type: "select",
      isMultiSelect: true,
      options: [],
      required: true,
      placeholder: "Select Company/Companies",
    },
    {
      title: "Category",
      id: "category",
      type: "select",
      isMultiSelect: true,
      options: [],
      required: true,
      placeholder: "Select Category/Categories",
    },
    {
      title: "Update user",
      type: "button",
    },
  ],
];

export const editVendorUserSchema = [
  [
    {
      title: "Name",
      id: "name",
      type: "text",
      required: true,
    },
    {
      title: "Phone Number",
      id: "phoneNumber",
      type: "number",
      required: true,
    },
    {
      title: "Email Id",
      id: "emailId",
      type: "email",
      required: true,
    },
    {
      title: "Company",
      id: "company",
      type: "select",
      options: [],
      required: true,
      placeholder: "Select Company",
    },
    {
      title: "Category",
      id: "category",
      type: "select",
      isMultiSelect: true,
      options: [],
      required: true,
      placeholder: "Select Category/Categories",
    },
    {
      title: "Update user",
      type: "button",
    },
  ],
];
