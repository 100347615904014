/* eslint-disable no-unused-expressions */
import Joi from "joi";
import { gstNumberValidationSchema } from "./planning";

export const InvoiceTypes = [{
    label: "Delivery",
    value: "onlyDelivery"
}, {
    label: "BuyBack Pickup",
    value: "onlyBuyback"
}, {
    label: "Delivery + Buyback",
    value: "buyback"
}, {
    label: "Defective Pickup",
    value: "defective"
}, {
    label: "Replacement Pickup & Delivery",
    value: "replacement"
}];

export const invoiceFieldTypeMap = {
    "serialNo": "Serial No",
    "quantity": "Quantity",
    "hsnCode": "HSN Code",
    "amount": "Amount",
    "gstRate": "GST Rate",
    "cgst": "CGST",
    "igst": "IGST",
    "sgst": "SGST",
    "gstin": "GST Number",
    "buybackDetails": "Buyback Info",
    "defectiveDetails": "Defective Info",
    "newSerialNo": "New Serial No"
};
export const invoiceTypeFieldTypes = {
    "onlyDelivery": [["serialNo", "quantity", "hsnCode", "amount"], ["gstRate", "cgst", "sgst", "gstin"]],
    "onlyBuyback": [["quantity", "hsnCode", "amount", "gstRate"], ["cgst", "sgst", "gstin"]],
    "buyback": [["serialNo", "quantity", "buybackDetails", "hsnCode"], ["amount", "gstRate", "cgst", "sgst", "gstin"]],
    "defective": [["serialNo", "quantity", "defectiveDetails", "hsnCode"], ["amount", "gstRate", "cgst", "sgst", "gstin"]],
    "replacement": [["serialNo", "quantity", "newSerialNo", "hsnCode"], ["amount", "gstRate", "cgst", "sgst", "gstin"]],
    "onlyPickup": [["quantity", "hsnCode", "amount"], ["gstRate", "cgst", "sgst", "gstin"]]
}

export const numberFields = ["amount", "cgst", "sgst", "quantity"];
export const returnFormFieldsSchema = (fields) => {
    const joiItemObjects = {};
    fields?.forEach(fieldSet => {
        fieldSet?.forEach(fieldName => {
            if (numberFields.indexOf(fieldName) > -1) {
                joiItemObjects[fieldName] = Joi.number().required();
            } else if (fieldName === "gstRate") {
                joiItemObjects[fieldName] = Joi.valid("0", "2.5", "6", "12", "14", "18", "24", "28").required();
            } else if (fieldName === "gstin") {
                joiItemObjects[fieldName] = Joi.string().required().custom(gstNumberValidationSchema, "Invalid GSTIN Number");
            } else {
                joiItemObjects[fieldName] = Joi.string().required();
            }
        });
    });
    joiItemObjects["companyName"] = Joi.string().allow("");
    joiItemObjects["productCategory"] = Joi.string().allow("");
    joiItemObjects["productCode"] = Joi.string().allow("");
    // add product related info here
    joiItemObjects["product"] = Joi.object({
        productName: Joi.string().required(),
        companyName: Joi.string().required(),
        productCategory: Joi.string().required(),
        vanNo: Joi.string().required(),
        productCode: Joi.string().required(),
        createdAt: Joi.string().required(),
        updatedAt: Joi.string().required(),
        objectId: Joi.string().required()
    }).required();
    return Joi.object(joiItemObjects);
}