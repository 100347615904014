import { useEffect } from 'react';
import { getProductsByCompaniesCategoriesSubCategories } from '../../../parse-functions/sms/scheme';
import { convertProductsToDropdownOptions } from '../../../utils/sms/convertProductsToDropdownOptions';
import { setMatchingProductsInStore } from '../../../actions/products';
import { useDispatch } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';

const useFetchProducts = (
  formInput,
  setFormInput,
  updatedSchema,
  setUpdatedSchema,
  schema
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        dispatch(updateSpinnerState(true));
        let newSchema = updatedSchema;
        if (formInput.company?.length >= 1) {
          const newOptions =
            await getProductsByCompaniesCategoriesSubCategories({
              companiesName: formInput.company,
              categoriesName: formInput.category,
              subCategories: formInput.subCategory,
            });
          dispatch(setMatchingProductsInStore(newOptions));
          newSchema = schema.map((section) =>
            section.map((field) => {
              if (field?.title === 'Product') {
                if (newOptions.length > 0) {
                  field.options = [
                    { label: 'All', value: 'All' },
                    ...convertProductsToDropdownOptions(newOptions),
                  ];
                } else {
                  field.options = [];
                }
              }
              return field;
            })
          );
          const newProducts = newOptions.map((item) => item.productName);
          setFormInput((prev) => ({
            ...prev,
            products: prev.products?.filter((item) =>
              newProducts.includes(item)
            ),
          }));
        }
        setUpdatedSchema(newSchema);
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        console.error('Error fetching products:', error);
      }
    };
    if (
      schema.some((section) => section.some((item) => item.title === 'Product'))
    ) {
      if (
        formInput.company?.length >= 1 &&
        formInput.category?.length >= 1 &&
        (schema?.[2]?.[1].options.length > 0
          ? formInput.subCategory?.length >= 1
          : true)
      ) {
        fetchProducts();
      } else {
        setUpdatedSchema((prev) =>
          prev.map((section) =>
            section.map((field) => {
              if (field?.title === 'Product') {
                field.options = [];
              }
              return field;
            })
          )
        );
      }
    }
  }, [formInput.category, formInput.subCategory]);

  useEffect(() => {
    setUpdatedSchema(schema);
  }, [schema]);

  useEffect(() => {
    setFormInput((prev) => {
      let updatedInput = { ...prev };
      updatedInput.category = [];
      updatedInput.subCategory = [];
      updatedInput.products = [];
      return updatedInput;
    });
  }, [formInput.company]);

  useEffect(() => {
    if (
      formInput.category?.includes('All') &&
      !formInput.subCategory?.includes('All') &&
      Object.keys(schema).includes('subCategory')
    ) {
      setFormInput((prev) => ({
        ...prev,
        subCategory: ['All'],
      }));
    }
  }, [formInput.category]);
};

export default useFetchProducts;
