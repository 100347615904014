import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiCall } from '../../../../middlewares/api';
import OrderListPage from '../../../../components/common/OrderListPage';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import { updateSpinnerState } from '../../../../actions/spinner';
import { updateToastInfo } from '../../../../actions/settings';
import ConfirmationModal from '../../../../components/common/confirmationModal';
import { getColumns, sameorderItemsColumns } from '../utils';
import { ShowAssignModal } from './ShowAssignModal';
import {
  orderStatus,
  returnOrderStatus,
  cancelableStatus,
} from '../../../../constants/orderStatus';
import { isBelongsToSameInvoice, showShippingLabelDownload } from './utils';
import { apiCallConsts } from '../../../../constants/apiCallConsts';
import PurchaseDataModal from '../../../scheme-management/Components/IndividualScheme.js/PurchaseDataModal';
import ProductReleaseModal from '../../productsRelease/components/ProductReleaseModal';
import CancellationModal from './CancellationModal';

const OrderPage = (props) => {
  const {
    type,
    showFilter,
    bulkActionOptions,
    defaultConfirmationProps,
    confirmationModalProps,
    setConfirmationModalProps,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeName = useSelector(
    (state) => state.user.preferences.storeName || ''
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const zone = useSelector((state) => state?.user?.preferences?.zone || '');
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [updateMode, setUpdateMode] = useState('');
  const [ordersData, setOrdersData] = useState([]);
  const breadCrumbItems = arrayToBreadCrumbs([[t('Orders')], [t(type)]]);
  const isPreRegistered = type === orderStatus.preRegistered || false;
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [ordersStatusData, setOrderStatusData] = useState([]);
  const [showQcFailModal, setShowQcFailModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        isPreRegistered
          ? `/internal/order/${orderStatus.preRegistered}`
          : `/internal/order?storeCode=${storeName}&status=${type}&region=${zone}`
      );
      setOrdersData(res);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Orders fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Orders', error.message);
    }
  };

  const updateOrders = async (data) => {
    await apiCall('put', `/internal/order/update`, data);
  };

  const onRefreshClick = () => {
    fetchOrders();
    setSelectedOrders([]);
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedOrders((prev) => {
        const selectedItem = ordersData.find(
          (item) => item.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedOrders((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setOrdersData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleShowAssignModal = () => {
    setShowAssignModal(true);
  };

  const handleShowQCFailModal = () => {
    setShowQcFailModal(true);
  };

  const isBelongsToSameStatus = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const orderNo = selectedOrders[0].orderNo;
      const store = isAdmin ? selectedOrders[0].storeCode : storeName;
      const result = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/order?storeCode=${store}&orderNo=${orderNo}`
      );
      dispatch(updateSpinnerState(false));
      return result;
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'failed to update orders!', error.message);
    }
  };

  const handleSelectedRowsUpdate = async (actionId) => {
    if (selectedOrders?.length > 0) {
      if (
        selectedOrders?.length > 1 &&
        (actionId === 'awaitingInvoice' ||
          actionId === 'invoiced' ||
          actionId === 'qcFail' ||
          actionId === 'accept' ||
          actionId === 'reject')
      ) {
        messageDisplayer(
          'danger',
          'Max 1 order to update',
          'Please select maximum of 1 order'
        );
        return;
      }
      if (selectedOrders?.length > 1 && actionId === 'cancelOrder') {
        const result = isBelongsToSameInvoice(selectedOrders);
        if (!result) {
          messageDisplayer(
            'danger',
            'Failed to update Orders',
            'please select same invoiced orders'
          );
          return;
        }
      }
      if (actionId === 'invoiced') {
        const result = await isBelongsToSameStatus();
        if (result?.length > 0) {
          setOrderStatusData(result);
          setShowStatusModal(true);
          return;
        } else {
          const sameOrderItems = ordersData.filter(
            (order) =>
              order.orderNo === selectedOrders[0].orderNo &&
              order.storeCode === selectedOrders[0].storeCode
          );
          const sameOrderItemIds = sameOrderItems.map((item) => item.serialNo);
          setOrderStatusData(sameOrderItemIds);
        }
      }
      if (actionId === 'cancelOrder') {
        try {
          dispatch(updateSpinnerState(true));
          const result = await apiCall(
            apiCallConsts.GET_METHOD,
            `${apiCallConsts.ORDER_DETAILS_URL}${selectedOrders[0].objectId}`,
            {}
          );
          dispatch(updateSpinnerState(false));
          if (result[0]?.items) {
            setOrderStatusData(
              result[0]?.items?.filter(
                (item) => item.status !== orderStatus.cancelled
              )
            );
            setShowCancelModal(true);
            return;
          } else {
            setOrderStatusData(result[0]);
          }
        } catch (error) {
          messageDisplayer('danger', '', 'Error getting Order Data!');
        }
      }
      if (actionId === 'pickedUp') {
        const isCheckNeeded = showShippingLabelDownload(selectedOrders);

        if (isCheckNeeded) {
          const isShippingLabelDownloaded = selectedOrders.every(
            (order) => order.isShippingLabelDownloaded
          );
          if (!isShippingLabelDownloaded) {
            messageDisplayer(
              'danger',
              'Failed to update Orders',
              'please download shipping label before updating!'
            );
            return;
          }
        }
      }
      handleActionSwitch(actionId);
    }
  };

  const handleActionSwitch = (actionId) => {
    switch (actionId) {
      case 'accept':
        setUpdateMode(orderStatus.accepted);
        handleShowDeleteModal();
        break;
      case 'reject':
        setUpdateMode(orderStatus.rejected);
        setConfirmationModalProps(defaultConfirmationProps['rejected']);
        handleShowDeleteModal();
        break;
      case 'awaitingInvoice':
        setUpdateMode(orderStatus.awaitingInvoice);
        setConfirmationModalProps(defaultConfirmationProps['awaitingInvoice']);
        handleShowDeleteModal();
        break;
      case 'invoiced':
        setUpdateMode(orderStatus.invoiced);
        setConfirmationModalProps(defaultConfirmationProps['invoiced']);
        handleShowDeleteModal();
        break;
      case 'readyForPickup':
        setUpdateMode(orderStatus.readyForPickup);
        handleShowDeleteModal();
        break;
      case 'pickedUp':
        setUpdateMode(orderStatus.pickedUp);
        handleShowDeleteModal();
        break;
      case 'Out For Delivery':
        setUpdateMode(orderStatus.outForDelivery);
        handleShowDeleteModal();
        break;
      case 'assign':
        setUpdateMode(orderStatus.accepted);
        handleShowAssignModal();
        break;
      case 'cancelOrder':
        setUpdateMode(orderStatus.cancelled);
        setShowCancelModal(true);
        break;
      case 'qcFail':
        setUpdateMode(orderStatus.qcfailed);
        setConfirmationModalProps(defaultConfirmationProps['qcFail']);
        handleShowQCFailModal();
        break;
      case 'refundInitated':
        setUpdateMode(returnOrderStatus.REFUND_INITIATED);
        handleShowDeleteModal();
        break;
      case 'On Hold':
        setUpdateMode('On Hold');
        handleShowDeleteModal();
        break;
      case 'stopCancellation':
        const prevStatus =
          selectedOrders[0]?.actionLog[selectedOrders[0]?.actionLog?.length - 2]
            ?.action;
        const isCancellable = cancelableStatus.includes(prevStatus);
        if (!isCancellable) {
          messageDisplayer(
            'danger',
            'Failed',
            'Cannot Stop Cancellation, Invoice Already Generated'
          );
          break;
        }
        setUpdateMode(prevStatus);
        handleShowDeleteModal();
        break;
      default:
        break;
    }
  };

  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await updateOrders(data);
      await fetchOrders();
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Update Successful!');
      setShowDeleteModal(false);
      if (updateMode === 'Accepted') {
        setShowAssignModal(false);
      }
      // if (updateMode === 'On Hold') {
      // }
      if (updateMode === orderStatus.qcfailed) {
        setShowQcFailModal(false);
      }
      setShowCancelModal(false);
      setSelectedOrders([]);
      defaultConfirmationProps && setConfirmationModalProps([]);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Cannot be updated!', e.message);
    }
  };
  const handleModalOnConfirmClick = (storeCode) => {
    const selectedOrdersData = {
      id: selectedOrders.map((item) => item.objectId),
      status: updateMode,
      username: userName,
    };
    if (type !== orderStatus.cancelled) {
      switch (updateMode) {
        case orderStatus.rejected:
          selectedOrdersData.reasonForRejection =
            confirmationModalProps[0].value[0];
          selectedOrdersData.remarks = confirmationModalProps[0].remarks;
          break;
        case orderStatus.awaitingInvoice:
          selectedOrdersData.serialNo = confirmationModalProps[0].value;
          break;
        case orderStatus.invoiced:
          selectedOrdersData.invoiceNo = confirmationModalProps[0].value;
          break;
        case orderStatus.accepted:
          selectedOrdersData.storeCode =
            storeCode[0] || selectedOrders[0].storeCode;
          break;
        case orderStatus.cancelled:
          selectedOrdersData.SRTNo = confirmationModalProps[0].value;
          break;
        case orderStatus.qcfailed:
          selectedOrdersData.serialNo = confirmationModalProps[0].value;
          break;
        default:
      }
      if (updateMode === orderStatus.invoiced) {
        const allSameOrderIds = ordersData
          .filter((order) => ordersStatusData.includes(order.serialNo))
          .map((order) => order.objectId);
        selectedOrdersData.id = [...allSameOrderIds];
      }
    }
    handleOnConfirmClick(selectedOrdersData);
  };

  const handleCancelModalConfirmClick = (data, formInput) => {
    let result = {};
    let cancelledIds = [];
    let invoicedIds = [];
    if (data?.length === 1) {
      cancelledIds = [data[0].objectId];
    } else {
      cancelledIds = data
        .filter((item) => item.status === orderStatus.cancelled)
        .map((item) => item.objectId);

      invoicedIds = data
        .filter((item) => item.status !== orderStatus.cancelled)
        .map((item) => item.objectId);
    }
    if (formInput.invoiceNumber && invoicedIds?.length > 0) {
      result = {
        id: [...cancelledIds, ...invoicedIds],
        cancelledId: cancelledIds,
        invoicedId: invoicedIds,
        status: orderStatus.cancelled,
        username: userName,
        SRTNo: formInput.srtNumber,
        invoiceNo: formInput.invoiceNumber,
      };
    } else {
      result = {
        id: cancelledIds,
        status: orderStatus.cancelled,
        username: userName,
        SRTNo: formInput.srtNumber,
      };
    }

    console.log(result);
    handleOnConfirmClick(result);
  };

  const handleTimeOut = async (id) => {
    try {
      const data = {
        id: [id],
        status: orderStatus.autoRejected,
      };
      // await updateOrders(data);
      setOrdersData((prev) =>
        prev.filter(
          (order) =>
            order.isBiker || order.isHyperLocal || order.objectId !== id
        )
      );
      // await fetchOrders();
    } catch (error) {
      messageDisplayer('danger', 'Failed to handle timeout', error.message);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      fetchOrders();
    }
    if (storeName) {
      fetchOrders();
    }
    if (zone) {
      fetchOrders();
    }
  }, [storeName, isAdmin]);

  return (
    <>
      <OrderListPage
        pageType={`${type}orders`}
        showDateFilters={showFilter}
        tableData={ordersData}
        tableColumns={getColumns(
          type,
          onRowSelect,
          navigate,
          handleTimeOut,
          bulkActionOptions,
          isAdmin
        )}
        handleRefresh={onRefreshClick}
        pageTitle={t(`${type} Orders`)}
        breadCrumbItems={breadCrumbItems}
        enableBulkActions={selectedOrders?.length > 0}
        hideBulkActions={!bulkActionOptions}
        bulkActionOptions={bulkActionOptions}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowDeleteModal(false);
          defaultConfirmationProps && setConfirmationModalProps([]);
        }}
        modalBody={t(
          type === orderStatus.accepted &&
            updateMode !== orderStatus.rejected &&
            updateMode !== 'On Hold'
            ? `Please Enter Serial No for QC Passed Items`
            : type === orderStatus.awaitingInvoice &&
              ordersStatusData.length > 1
            ? `These Items Are Moving To Next Stage, They Belongs To Same Order!  
                Serial No's are:  ${ordersStatusData.join(', ')}`
            : `Are you sure you want to change the status to ${updateMode}?`
        )}
        modalTitle={t(`${updateMode} Order(s)`)}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
      {showAssignModal && (
        <ShowAssignModal
          showModal={showAssignModal}
          onConfirm={handleModalOnConfirmClick}
          closeModal={() => setShowAssignModal(false)}
          modalTitle={t(`${updateMode} Order(s)`)}
        />
      )}
      <PurchaseDataModal
        showModal={showStatusModal}
        closeModal={() => setShowStatusModal(false)}
        data={ordersStatusData}
        columns={sameorderItemsColumns(isAdmin)}
        modalTitle={'Action Need To Be Taken'}
        showRowBgColor={true}
      />
      <ProductReleaseModal
        showModal={showQcFailModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowQcFailModal(false);
          defaultConfirmationProps && setConfirmationModalProps([]);
        }}
        modalBody={t(`please Enter Serial No for QC Failed Items`)}
        modalTitle={t(`${updateMode} Order(s)`)}
        ModalProps={confirmationModalProps}
        setModalProps={setConfirmationModalProps}
        allowOptionalValues={true}
      />
      <CancellationModal
        data={ordersStatusData}
        showModal={showCancelModal && ordersStatusData.length > 0}
        closeModal={() => setShowCancelModal(false)}
        onConfirm={handleCancelModalConfirmClick}
      />
    </>
  );
};
export default OrderPage;
