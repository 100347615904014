import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateSpinnerState } from "../../../../actions/spinner";
import { getSchemeById } from "../../../../parse-functions/sms/scheme";
import { useParams } from "react-router-dom";
import FormComponent from "../FormComponent";
import BulkAddSchemeModal from "../BulkAddSchemeModal";
import { bulkAddSchemeColumns } from "../../../../utils/sms/BulkAddSchemeColumns";
import { useTranslation } from "react-i18next";
import BreadCrumbComponent from "../../../../components/common/Breadcrumb";
import { Routes } from "../../../../router/routes";
import arrayToBreadCrumbs from "../../../../utils/sms/arrayToBreadCrumbs";

const ViewSchemeDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [editData, setEditData] = useState({});
  const [showProductsModal, setShowProductsModal] = useState(false);

  let breadCrumbItems;
  switch (editData.status) {
    case "active": {
      breadCrumbItems = arrayToBreadCrumbs([
        [t("Active"), Routes.SMS.Target.ActiveSchemes.path],
        [t("Scheme Info")],
      ]);
      break;
    }
    case "closed": {
      breadCrumbItems = arrayToBreadCrumbs([
        [t("Closed"), Routes.SMS.Target.ClosedSchemes.path],
        [t("Scheme Details")],
      ]);
      break;
    }
    case "cancelled": {
      breadCrumbItems = arrayToBreadCrumbs([
        [t("Rejected"), Routes.SMS.Target.RejectedSchemes.path],
        [t("Scheme Info")],
      ]);
      break;
    }
    case "rejected": {
      breadCrumbItems = arrayToBreadCrumbs([
        [t("Rejected"), Routes.SMS.Target.RejectedSchemes.path],
        [t("Scheme Info")],
      ]);
      break;
    }
    case "completed": {
      breadCrumbItems = arrayToBreadCrumbs([
        [t("Active"), Routes.SMS.Target.ActiveSchemes.path],
        [t("Scheme Info")],
      ]);
      break;
    }
    case "draft": {
      breadCrumbItems = arrayToBreadCrumbs([
        [t("Draft"), Routes.SMS.Target.DraftSchemes.path],
        [t("Scheme Info")],
      ]);
      break;
    }
    default: {
      break;
    }
  }

  const schema = [
    [
      {
        title: "Company",
        isMultiSelect: false,
        type: "select",
        id: "company",
        options: [],
        disabled: true,
      },
      {
        title: "Scheme Circular No.",
        id: "schemeCircularNo",
        type: "text",
        disabled: true,
      },
      {
        title: "Scheme Circular Date",
        id: "schemeCircularDate",
        type: "date",
        disabled: true,
      },
      {
        title: "Regions",
        id: "regions",
        type: "select",
        isMultiSelect: true,
        options: [],
        disabled: true,
      },
      {
        title: "Valid From",
        id: "validFrom",
        type: "date",
        disabled: true,
      },
      {
        title: "Valid Till",
        id: "validTill",
        type: "date",
        disabled: true,
      },
    ],
    [
      {
        title: "Scheme Type",
        id: "type",
        type: "select",
        options: [],
        disabled: true,
      },
      {
        title: "Remarks",
        id: "remarks",
        type: "textarea",
        rows: 5,
        disabled: true,
      },
      {
        title: "View Products",
        type: "button",
      },
    ],
  ];

  if (editData?.type?.[0] === "Sell-in") {
    schema[1].splice(1, 0, {
      title: "Sell In Type",
      id: "sellIntype",
      type: "select",
      options: [],
      required: false,
      disabled: true,
    });
  }
  if (editData?.type?.[0] === "Sell-out") {
    const cappingTypeObject = {
      title: "Capping Type",
      id: "cappingType",
      type: "select",
      options: [],
      required: false,
      disabled: true,
    };
    const cappingAmountObject = {
      title: "Capping Amount",
      id: "cappingAmount",
      type: "number",
      required: false,
      disabled: true,
    };
    schema[1] = [
      ...schema[1].slice(0, 1),
      cappingTypeObject,
      cappingAmountObject,
      ...schema[1].slice(1),
    ];
  }

  const saveFunction = () => {
    setShowProductsModal(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const schemeDataResult = await getSchemeById({ id });
        const products = schemeDataResult?.products.map((item) => ({
          ...item,
          category: item.productCategory || item.category,
          subCategory: item.subCategory,
        }));
        setEditData({ ...schemeDataResult, products: products });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        dispatch(updateSpinnerState(false));
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbComponent items={breadCrumbItems} />
          <h4>{t(`Scheme Information`)}</h4>
          <p className="mb-0">
            {t(`All Scheme related Information will be displayed here!`)}
          </p>
        </div>
      </div>
      {Object.keys(editData).length > 0 && (
        <FormComponent
          schema={schema}
          editData={editData}
          savefunction={saveFunction}
        />
      )}

      <BulkAddSchemeModal
        data={editData?.products || []}
        showModal={showProductsModal}
        closeModal={() => setShowProductsModal(false)}
        columns={bulkAddSchemeColumns()}
      />
    </div>
  );
};

export default ViewSchemeDetails;
