import { format, isValid, lastDayOfMonth } from "date-fns";

export const returnReadableDate = (timeInMs) => {
    if (!timeInMs) {
        return "";
    }
    return new Date(timeInMs).toString().substring(4, 15);
}
export const returnUserReadableDateTime = (date) => {
    const dateHere = new Date(date);
    if (isValid(dateHere)) {
        return format(dateHere, "PPpp");
    }
}

export const returnUserReadableMonthYear = (date) => {
    const dateHere = new Date(date);
    if (isValid(dateHere)) {
        return `${format(dateHere, "MMMM")}, ${format(dateHere, "y")}`;
    }
}
export const returnUserReadableDate = (date) => {
    const dateHere = new Date(date);
    if (isValid(dateHere)) {
        return format(dateHere, "PPP");
    }
    return date
}

export const returnStartOfDay = (date) => {
    if (date) {
        const dateHere = new Date(date);
        dateHere.setHours(0);
        dateHere.setMinutes(0);
        dateHere.setSeconds(0);
        dateHere.setMilliseconds(0);
        return dateHere;
    }
    return date;
}
export const returnStartOfMonth = (date) => {
    if (date) {
        const dateHere = new Date(date);
        dateHere.setDate(1);
        dateHere.setHours(0);
        dateHere.setMinutes(0);
        dateHere.setSeconds(0);
        dateHere.setMilliseconds(0);
        return dateHere;
    }
    return date;
}

export const returnEndOfMonth = (date) => {
    if (date) {
        let dateHere = new Date(date);
        dateHere = lastDayOfMonth(dateHere);
        return returnEndOfDay(dateHere);
    }
    return date;
}
export const returnEndOfDay = (date) => {
    if (date) {
        const dateHere = new Date(date);
        dateHere.setHours(23);
        dateHere.setMinutes(59);
        dateHere.setSeconds(59);
        dateHere.setMilliseconds(999);
        return dateHere;
    }
    return date;
}