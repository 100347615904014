import { returnReadableDate } from '../datetime';
import { returnCustomerAddressString } from '../table';

export const individualSchemeTable = (showMore) => {
  const formatDecimal = (cell) => {
    if (typeof cell === 'number') {
      return cell.toFixed(3);
    }
    return cell;
  };

  if (showMore) {
    return [
      {
        dataField: 'Branch',
        sort: true,
        text: 'Branch',
      },
      {
        dataField: 'PurchaseDate',
        sort: true,
        text: 'Purchase Date',
        formatter: (cell, row) => {
          return returnReadableDate(cell);
        },
      },
      {
        dataField: 'Product',
        text: 'Product',
        formatter: (cell, row) => {
          return returnCustomerAddressString(cell, null, 200, 30, 'top');
        },
      },
      {
        dataField: 'PurchaseVNo',
        text: 'Voucher No.',
      },
      {
        dataField: 'TotalQuantity',
        text: 'Total Quantity',
      },
      {
        dataField: 'TotalAmount',
        text: 'Total Amount',
        formatter: formatDecimal,
      },
      {
        dataField: 'TotalSGST',
        text: 'Total SGST',
        formatter: formatDecimal,
      },
      {
        dataField: 'TotalCGST',
        text: 'Total CGST',
        formatter: formatDecimal,
      },
      {
        dataField: 'TotalIGST',
        text: 'Total IGST',
        formatter: formatDecimal,
      },
      {
        dataField: 'TotalGSTAmount',
        text: 'Total GST Amount',
        formatter: formatDecimal,
      },
    ];
  } else {
    return [
      {
        dataField: 'branch',
        sort: true,
        text: 'Branch',
      },
      {
        dataField: 'product',
        text: 'Product',
        formatter: (cell, row) => {
          return returnCustomerAddressString(cell, null, 200, 30, 'top');
        },
      },
      {
        dataField: 'serialNo',
        text: 'Serial No',
      },
      {
        dataField: 'vanNo',
        text: 'VanNo',
      },
      {
        dataField: 'quantity',
        text: 'Quantity',
      },
      {
        dataField: 'amount',
        text: 'Amount',
      },
      {
        dataField: 'SGST',
        text: 'SGST',
      },
      {
        dataField: 'CGST',
        text: 'CGST',
      },
      {
        dataField: 'IGST',
        text: 'IGST',
      },
    ];
  }
};
