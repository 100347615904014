import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../../../actions/settings";
import { updateSpinnerState } from "../../../../actions/spinner";
import ParcelStatusPage from "../../../../components/common/parcelStatusPages";
import ConfirmationModal from "../../../../components/common/confirmationModal";
import arrayToBreadCrumbs from "../../../../utils/sms/arrayToBreadCrumbs";
import { usersTableColumns } from "../../../../utils/sms/usersTable";
import { getSMSUsers } from "../../../../parse-functions/sms/getUserInfo";
import UserEditModal from "../UserEditModal";
import {
  updateUserStatus,
  updateUsers,
} from "../../../../parse-functions/sms/addUsers";

const SMSUserList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const preferences = useSelector((state) => state?.user?.preferences || {});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [updateMode, setUpdateMode] = useState("");
  const [showFormEditModal, setShowFormEditModal] = useState(false);
  const [editData, setEditData] = useState();
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );
  const isAccountManager = useSelector(
    (state) => state?.user?.preferences?.isAccountManager || false
  );

  const role = isAdmin
    ? "isAdmin"
    : isBrandManager
    ? "isBrandManager"
    : isAccountManager
    ? "isAccountManager"
    : "Unknown";

  const breadCrumbItems = arrayToBreadCrumbs([["Users"], ["SMS Users"]]);

  const getSMSUsersList = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const smsUsers = await getSMSUsers({
        id: preferences.userId,
        role,
      });
      setUsersData(smsUsers);
      setSelectedUsers([]);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: "success",
          title: t("SMS Users List fetched"),
          message: t("All the Users that use the SMS system"),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: "danger",
          title: t("Error"),
          message: t(e.message),
        })
      );
    }
  };

  const onRefreshClick = () => {
    getSMSUsersList();
  };
  const onRowSelect = (selected, objectId) => {
    setSelectedUsers((prev) => {
      if (!selected) {
        const { [objectId]: _, ...newState } = prev;
        return newState;
      }

      return {
        ...prev,
        [objectId]: selected,
      };
    });
    setUsersData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };
  const handleShowDeleteModal = () => {
    setShowConfirmationModal(true);
  };

  const handleSelectedRowsUpdate = (actionId) => {
    if (Object.keys(selectedUsers)?.length > 0) {
      if (Object.keys(selectedUsers)?.length > 1 && actionId === "edit") {
        dispatch(
          updateToastInfo({
            show: true,
            type: "danger",
            title: t("Max 1 users to select"),
            message: t("Please select maximum of 1 user for editing"),
          })
        );
        return;
      }
      // we can do edit and delete here
      switch (actionId) {
        case "inactive": {
          setUpdateMode("Inactivate");
          handleShowDeleteModal();
          break;
        }
        case "edit": {
          setUpdateMode("Edit");
          let data = usersData.find(
            (user) => user.objectId === Object.keys(selectedUsers)[0]
          );
          if (data.role !== "Account Manager") {
            data = {
              ...data,
              category: data.productCategory,
            };
          }

          setEditData(data);
          setShowFormEditModal(true);
          break;
        }
        case "active": {
          setUpdateMode("Activate");
          handleShowDeleteModal();
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const handleCloseFormEditModal = () => {
    setShowFormEditModal(false);
  };
  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await updateUserStatus(data);
      getSMSUsersList();
      setSelectedUsers([]);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: "success",
          title: "User Update Successful",
          message: "",
        })
      );
      setShowConfirmationModal(false);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: "danger",
          title: "User Update Failed",
          message: "Please contact support for help",
        })
      );
    }
  };
  const handleModalOnConfirmClick = () => {
    const userId = usersData
      .filter((item) => Object.keys(selectedUsers).includes(item.objectId))
      .map((item) => item.userId);

    const updatedUsers = {
      id: userId,
      status: updateMode === "Activate" ? "active" : "inactive",
    };
    handleOnConfirmClick(updatedUsers);
  };
  const handleFormSave = async (data) => {
    await updateUsers(data);
    handleCloseFormEditModal();
    getSMSUsersList();
  };

  useEffect(() => {
    if (role !== "Unknown") {
      getSMSUsersList();
    }
  }, [role]);

  return (
    <>
      <ParcelStatusPage
        pageType={"smsuserlist"}
        showDateFilters={false}
        tableData={usersData}
        tableColumns={usersTableColumns(onRowSelect)}
        handleRefresh={onRefreshClick}
        pageTitle={t("SMS User List")}
        breadCrumbItems={breadCrumbItems}
        pageSubTitle={t("All the Users who use the Platform.")}
        searchPlaceHolder={t("Search By Name, Role etc")}
        enableBulkActions={Object.keys(selectedUsers)?.length > 0}
        bulkActionOptions={[
          {
            label: "Edit",
            value: "edit",
          },
          {
            label: "Activate",
            value: "active",
          },
          {
            label: "Deactivate",
            value: "inactive",
          },
        ]}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      <ConfirmationModal
        showModal={showConfirmationModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowConfirmationModal(false);
        }}
        modalBody={t(`Are you sure you want to ${updateMode} these users`)}
        modalTitle={t("Delete Users")}
      />
      <UserEditModal
        editData={editData}
        show={showFormEditModal}
        handleSave={handleFormSave}
        topTitle="Edit Users"
        handleClose={handleCloseFormEditModal}
      />
    </>
  );
};
export default SMSUserList;
