import { combineReducers } from "redux";
import orders from "./orders";
import vehicles from "./vehicles";
import settings from "./settings";
import spinner from "./spinner";
import user from "./users";
import trips from "./trips";
import parcels from "./parcels";
import products from "./products";
import vendors from "./vendors";
import drivers from "./drivers";
import requisitions from "./requisitions";
import filters from "./filters";
import loaders from "./loaders";
import temposheet from "./temposheet";
import productsExcelUpload from "./sms/productsExcelUpload";
import dmsUsersList from './dmsUsers';
import teamLeads from './wms/teamLeads';
import warehouseTargets from "./wms/targets";

const rootReducer = combineReducers({
    orders,
    vehicles,
    settings,
    spinner,
    user,
    trips,
    parcels,
    products,
    vendors,
    drivers,
    requisitions,
    filters,
    loaders,
    temposheet,
    dmsUsers: dmsUsersList,
    teamLeads,
    warehouseTargets,
    productsExcelUpload
});

export default rootReducer;
