import React, { useEffect, useState } from 'react';
import { styles } from '../style';
import { Button, Card } from '@themesberg/react-bootstrap';
import DropdownComponent from '../../../../components/common/Dropdown';
import AsyncDropdown from '../../../../components/AsyncDropdown';
import { StyledSearchBar } from '../../../trips/styles';
import { useDispatch, useSelector } from 'react-redux';
import { returnWarehouseDropdownOptions } from '../../../../utils/warehouse';
import { arrayToDropdownOptions } from '../../../../utils/sms/arrayToDropdownOptions';
import { updateToastInfo } from '../../../../actions/settings';
import { useTranslation } from 'react-i18next';
import { debounce } from '../../../../utils/debounce';
import { apiCall } from '../../../../middlewares/api';
import { updateSpinnerState } from '../../../../actions/spinner';
import { apiCallConsts } from '../../../../constants/apiCallConsts';
import { setProductListDropdownInStore } from '../../../../actions/products';

const InventoryFilters = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const storeName = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );
  const productList = useSelector(
    (state) => state?.products?.productsListDropdown || {}
  );
  const productsListAsOptions = arrayToDropdownOptions(
    Object.keys(productList)
  );

  const zone = useSelector((state) => state?.user?.preferences?.zone);
  const userType = useSelector((state) => state?.user?.preferences?.userType);
  const [searchByFieldName, setSearchByFieldName] = useState('');
  const [selectedWarehouse, SetSelectedWarehouse] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const getWarehouseDropdownOptions = () => {
    return returnWarehouseDropdownOptions(otherWarehouses, true, zone);
  };

  const getWarehouseShortCode = (warehouseName) => {
    let options = getWarehouseDropdownOptions();
    const res = options?.filter((option) => option?.value === warehouseName);
    const shortCode = res.length > 0 ? res[0]?.data?.shortCode : '';
    return shortCode;
  };

  const hanleClickSubmit = () => {
    if (!searchByFieldName || !searchValue) {
      dispatch(
        updateToastInfo({
          show: true,
          type: t('danger'),
          title: '',
          message: t('please enter all fields'),
        })
      );
      return;
    }

    if (searchByFieldName === 'vanNo' && searchValue.length !== 6) {
      dispatch(
        updateToastInfo({
          show: true,
          type: t('danger'),
          title: '',
          message: t('please enter correct vanNo and pincode'),
        })
      );
      return;
    }

    const key =
      searchByFieldName === 'productName' ? 'vanNo' : searchByFieldName;
    const value =
      searchByFieldName === 'productName'
        ? productList[searchValue]
        : searchValue;
    const shortCode = isAdmin
      ? getWarehouseShortCode(selectedWarehouse)
      : getWarehouseShortCode(storeName);

    let queryparams = { [key]: value, storeCode: shortCode };
    if (isAdmin) {
      queryparams = { ...queryparams, isAdmin };
    }
    handleSubmit(queryparams);
  };

  const fetchOptions = debounce(async (inputValue) => {
    try {
      const filteredOptions = productsListAsOptions.filter((product) =>
        product.value.toLowerCase().includes(inputValue.toLowerCase())
      );
      return filteredOptions;
    } catch (e) {
      console.log(e);
    }
  });

  useEffect(() => {
    const getProductsList = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const products = await apiCall(
          apiCallConsts.GET_METHOD,
          '/internal/serviceability/products'
        );
        dispatch(setProductListDropdownInStore(products));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Failed Getting Products data'),
            message: t(error.message),
          })
        );
      }
    };
    if (Object.keys(productList).length === 0) {
      getProductsList();
    }
  }, []);

  return (
    <Card className="mb-2 pb-2">
      <Card.Body>
        <div className="d-flex justify-content-evenly gap-3 pt-2">
          {/* <div> */}
          <div style={styles.filters}>
            <p style={styles.filterTitle}>{t('Select field to search by')}</p>
            <DropdownComponent
              onChange={(option) => {
                setSearchByFieldName(option.value);
              }}
              defaultValue={searchByFieldName}
              width={'100%'}
              disabled={false}
              options={[
                { label: 'VAN No', value: 'vanNo' },
                { label: 'Product Name', value: 'productName' },
                { label: 'Company Name', value: 'companyName' },
              ]}
              placeholder={t('Select field to Search by')}
            />
          </div>

          <div style={styles.filters}>
            <p style={styles.filterTitle}>{t('Please enter value')}</p>
            {searchByFieldName === 'productName' ? (
              <AsyncDropdown
                onChange={(option) => setSearchValue(option.value)}
                width={'100%'}
                disabled={false}
                fetchOptions={fetchOptions}
                placeholder={t('Select field to Search by')}
              />
            ) : (
              <StyledSearchBar
                width={'100%'}
                heigth={'45px'}
                type={searchByFieldName === 'vanNo' ? 'number' : 'text'}
                placeholder={'Please Enter value'}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            )}
          </div>
          <div style={styles.filters}>
            {(isAdmin || userType == 'regionUser') && (
              <>
                <p style={styles.filterTitle}>{t('Select warehouse')}</p>
                <DropdownComponent
                  onChange={(option) => {
                    SetSelectedWarehouse(option.value);
                  }}
                  defaultValue={selectedWarehouse}
                  width={'100%'}
                  disabled={false}
                  options={getWarehouseDropdownOptions()}
                  placeholder={t('Select warehouse')}
                />
              </>
            )}
          </div>
          <div>
            <Button
              style={{
                height: '40px',
                width: '142px',
                borderRadius: '3.99px',
                marginTop: '34px',
              }}
              variant="primary"
              onClick={hanleClickSubmit}
            >
              {t('Fetch')}
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default InventoryFilters;
