import React, { useState } from 'react';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { Routes } from '../../../router/routes';
import { Button, Col, Nav, Row, Tab } from '@themesberg/react-bootstrap';
import ProductInventory from './productInventory';
import CompleteInventory from './CompleteInventory';
import { useTranslation } from 'react-i18next';
import ExcelDownloadReport from '../../../components/common/excel/download';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { StyledSearchBar } from '../../trips/styles';

const HomeInventory = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const [activeTab, setActiveTab] = useState('productInventory');
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [searchText, setSearchText] = useState('');

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const { t } = useTranslation();
  const breadCrumbItems = arrayToBreadCrumbs([
    ['Add Product', Routes.Admin.Product.Add],
    ['Inventory'],
  ]);

  const handleShowCategoryModal = () => {
    setShowCategoryModal(true);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-1">
        <div className="d-block mb-3 mb-md-0">
          <BreadCrumbComponent items={breadCrumbItems} />
          <div className="font-weight-medium fs-2 lh-base mb-0">
            {t('Products Inventory')}
          </div>
        </div>
      </div>
      <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
        <Row className="d-flex justify-content-between align-items-center">
          <Col md={8}>
            <Nav className="nav-tabs gap-2">
              <Nav.Item
                style={{
                  backgroundColor:
                    activeTab === 'productInventory' ? '#262B40' : 'white',
                  color: activeTab === 'productInventory' ? 'white' : '#262B40',
                  borderRadius: '4px',
                }}
              >
                <Nav.Link
                  eventKey="productInventory"
                  className="mb-sm-3 mb-md-0"
                >
                  {t('Selected Product Details')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                style={{
                  backgroundColor:
                    activeTab === 'completeInventory' ? '#262B40' : 'white',
                  color:
                    activeTab === 'completeInventory' ? 'white' : '#262B40',
                  borderRadius: '4px',
                }}
              >
                <Nav.Link
                  eventKey="completeInventory"
                  className="mb-sm-3 mb-md-0"
                >
                  {t('Complete Warehouse Inventory')}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={4} className="d-flex justify-content-end gap-2">
            {activeTab === 'productInventory' && (
              <>
                {isAdmin && (
                  <Button
                    style={{ height: '55.89px', borderRadius: '3.99px' }}
                    onClick={handleShowCategoryModal}
                    variant="primary"
                    className="me-2"
                  >
                    <FontAwesomeIcon icon={faPen} />
                    &nbsp;&nbsp; {t('Update Category Stock')}
                  </Button>
                )}
                <div className="btn-export">
                  <ExcelDownloadReport data={exportData} fileName="product" />
                </div>
              </>
            )}
            {activeTab === 'completeInventory' && (
              <>
                <StyledSearchBar
                  //   size="xs"
                  width={'360.89px'}
                  heigth={'53.89px'}
                  type={'text'}
                  value={searchText}
                  placeholder={'Please Enter value'}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Button
                  size="md"
                  onClick={() => {
                    setSearchText('');
                  }}
                  variant="primary"
                >
                  {t('Clear')}
                </Button>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Tab.Content>
              <Tab.Pane eventKey="productInventory" className="py-4">
                <ProductInventory
                  showCategoryModal={showCategoryModal}
                  setShowCategoryModal={setShowCategoryModal}
                  setExportData={setExportData}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="completeInventory" className="py-4">
                <CompleteInventory searchText={searchText} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default HomeInventory;
