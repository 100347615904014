import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import ParcelStatusPage from '../../../../components/common/parcelStatusPages';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import { getAllSchemes } from '../../../../parse-functions/sms/scheme';
import SchemeInfoModal from './SchemeInfoModal';
import { infoTable } from '../../../../utils/sms/infoTable';
import { useNavigate } from 'react-router-dom';
import { schemeTableColumns } from '../../../../utils/sms/schemeTable';
import { setFiltersInStore } from '../../../../actions/filters';
import { convertSchemeDataToTableData } from '../../../../utils/sms/convertProductsToDropdownOptions';
import BulkAddSchemeModal from '../BulkAddSchemeModal';
import { bulkAddSchemeColumns } from '../../../../utils/sms/BulkAddSchemeColumns';

const RejectedSchemes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );

  const isBrandVendor = useSelector(
    (state) => state?.user?.preferences?.isBrandVendor || false
  );

  const isAccountManager = useSelector(
    (state) => state?.user?.preferences?.isAccountManager || false
  );
  const filtersValue = useSelector(
    (state) => state.filters?.['rejectedSchemes']
  );
  const [schemeData, setSchemeData] = useState([]);
  const [selectedSchemes, setSelectedSchemes] = useState({});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoData, setInfoData] = useState();
  const [updatedSchemeData, setUpdatedSchemeData] = useState([]);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [id, setId] = useState();

  const role = isAdmin
    ? 'isAdmin'
    : isBrandManager
    ? 'isBrandManager'
    : isBrandVendor
    ? 'isBrandVendor'
    : isAccountManager
    ? 'isAccountManager'
    : 'Unknown';
  const breadCrumbItems = arrayToBreadCrumbs([
    [t('Schemes')],
    [t('Cancelled')],
  ]);
  const bulkActionOptions = [{ label: 'View', value: 'view' }];

  const fetchRejectedSchemes = async (startDate, endDate) => {
    const response = await getAllSchemes({
      status: ['rejected', 'cancelled'],
      role: role,
      startDate: startDate ? startDate : filtersValue?.startDate,
      endDate: endDate ? endDate : filtersValue?.endDate,
    });
    setUpdatedSchemeData(response);
    setSchemeData(convertSchemeDataToTableData(response));
  };

  const handleFiltersSubmit = async (startDate, endDate) => {
    dispatch(updateSpinnerState(true));
    dispatch(
      setFiltersInStore({
        pageType: 'rejectedSchemes',
        data: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        },
      })
    );
    try {
      await fetchRejectedSchemes(startDate, endDate);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          message: t('Schemes fetched successfully!'),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(e.message),
        })
      );
    }
  };

  const onRefreshClick = () => {
    try {
      fetchRejectedSchemes();
      setSelectedSchemes({});
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'Refreshed Schemes!',
          message: 'Schemes fetched successfully!',
        })
      );
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Error fetching Schemes!',
          message: error.message,
        })
      );
    }
  };

  const onRowSelect = (selected, objectId) => {
    setSelectedSchemes((prev) => {
      if (!selected) {
        const { [objectId]: _, ...newState } = prev;
        return newState;
      }
      return {
        ...prev,
        [objectId]: selected,
      };
    });
    setSchemeData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const handleSelectedRowsUpdate = (actionId) => {
    if (Object.keys(selectedSchemes).length === 1) {
      switch (actionId) {
        case 'view': {
          navigate(`/scheme/${Object.keys(selectedSchemes)[0]}/details`);
          break;
        }
        default: {
          break;
        }
      }
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Status cannot be updated!',
          message: 'Select a Maximum of 1 scheme',
        })
      );
    }
  };

  const handleInfoClick = (cell) => {
    setInfoData(cell);
    setShowInfoModal(true);
  };
  const handleMoreProductsClick = (id) => {
    setId(id);
    setShowProductsModal(true);
  };
  return (
    <>
      <ParcelStatusPage
        pageType={'rejectedSchemes'}
        showDateFilters={true}
        filtersValue={filtersValue}
        filterSubmit={handleFiltersSubmit}
        tableData={schemeData}
        tableColumns={schemeTableColumns(
          onRowSelect,
          'active',
          navigate,
          handleInfoClick,
          handleMoreProductsClick
        )}
        handleRefresh={onRefreshClick}
        pageTitle={t('Cancelled Schemes')}
        breadCrumbItems={breadCrumbItems}
        pageSubTitle={t('All the rejected and cancelled schemes')}
        enableBulkActions={Object.keys(selectedSchemes).length > 0}
        bulkActionOptions={bulkActionOptions}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      <SchemeInfoModal
        showModal={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        data={infoData}
        columns={infoTable()}
      />
      {id && (
        <BulkAddSchemeModal
          data={
            updatedSchemeData?.filter((item) => item.objectId === id)[0]
              ?.products || []
          }
          showModal={showProductsModal}
          closeModal={() => setShowProductsModal(false)}
          columns={bulkAddSchemeColumns()}
        />
      )}
    </>
  );
};
export default RejectedSchemes;
