import Parse from 'parse';

export const addNewScheme = async (data) => {
  return Parse.Cloud.run('addScheme', data);
};

export const getAllSchemes = async (data) => {
  return Parse.Cloud.run('getSchemeByStatus', data);
};

export const updateSchemesStatus = async (data) => {
  return Parse.Cloud.run('updateScheme', data);
};

export const getProductsByCompaniesCategoriesSubCategories = async (data) => {
  return Parse.Cloud.run('getProductsByCompaniesCategoriesSubCategories', data);
};

export const editScheme = async (data) => {
  return Parse.Cloud.run('editSchemeData', data);
};

export const getSchemeById = async (data) => {
  return Parse.Cloud.run('getSchemeById', data);
};

export const getSchemeData = async (data) => {
  return Parse.Cloud.run('getSchemeData', data);
};

export const getDetailedPurchase = async (data) => {
  return Parse.Cloud.run('getDetailedPurchase', data);
};

export const getAllSubCategories = async () => {
  return Parse.Cloud.run('getAllSubCategories');
};

export const getAllCompaniesCategoriesSubCategoriesData = async (data) => {
  return Parse.Cloud.run('getAllCompaniesCategoriesSubCategoriesData', data);
};
