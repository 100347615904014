import React from 'react';
import ReactDOM from 'react-dom';
import Parse from 'parse';
import 'dotenv/config';

// core styles
import './scss/volt.scss';

// vendor styles
import 'react-datetime/css/react-datetime.css';

import Root from './root';
import './i18n/init';

const parseServerUrl = process.env.REACT_APP_BACKENDURL;

// const parseServerUrl = "http://localhost:1337/parse";

Parse.initialize(
  'routing-server',
  'vijaysalessystemsmasterkey',
  'vijaysalessystemsmasterkey'
);
Parse.serverURL = parseServerUrl;
const renderApplication = () => {
  ReactDOM.render(<Root />, document.getElementById('root'));
};

renderApplication();
