import {
  faCalendarAlt,
  faCartPlus,
  faHome,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from '@themesberg/react-bootstrap';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../actions/settings';
import { updateSpinnerState } from '../../actions/spinner';
import { returnValidationErrorMessages } from '../../utils/validation';
import DropdownComponent from '../../components/common/Dropdown';
import AddedProductsCardList from '../../components/common/addManual/addedProductsCardList';
import { returnWarehouseDropdownOptions } from '../../utils/warehouse';
import { returnUserReadableDate } from '../../utils/datetime';
import {
  getAllCompaniesAndCategoriesData,
  getProductDataByVANNo,
  getProductsBasedOnCategoryAndCompany,
} from '../../parse-functions/products';
import {
  returnCategoriesDropdownList,
  returnCompaniesDropdownList,
  returnProductsDropdownList,
} from '../../utils/products';
import {
  setCompanyAndCategoryDataInStore,
  setMatchingProductsInStore,
} from '../../actions/products';
import { createNewRequisition } from '../../parse-functions/requisitions';

const createNewRequisitionSchema = Joi.object({
  toBranch: Joi.string().required(),
  deliveryBranch: Joi.string().required(),
  requisitionType: Joi.string().required(),
  hoApproval: Joi.string().required(),
  requisitionDate: Joi.date().required(),
  remarks: Joi.string(),
  product: Joi.any(),
  quantity: Joi.number(),
});
const AddNewRequisition = () => {
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [requisitionDate, setRequisitionDate] = useState();
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState('van');
  const [searchByText, setSearchByText] = useState('Search by VAN No.');
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );
  const warehouse = useSelector((state) => state?.user?.warehouse || {});
  const companies = useSelector((state) => state?.products?.companies || {});
  const categories = useSelector((state) => state?.products?.categories || {});
  const productsList = useSelector(
    (state) => state.products?.productsList || {}
  );
  const [formData, setFormData] = useState({});
  const [vanNo, setVanNo] = useState(null);
  const isSearchByVAN = searchBy === 'van';
  const [requisitionProducts, setRequisitionProducts] = useState([]);

  useEffect(() => {
    getAllCompaniesAndCategories();
  }, []);

  const onFormInputChange = (fieldName, value) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [fieldName]: value,
    }));
  };
  const fetchProductDetailsByVAN = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const data = await getProductDataByVANNo({ vanNo });
      const { product } = data;
      setFormData((oldData) => ({
        ...oldData,
        product,
      }));
      if (Object.keys(data)?.length > 0) {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'success',
            title: t('Product Info fetched'),
            message: t(
              'Product data has been fetched and added to the invoice'
            ),
          })
        );
      } else {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Failed getting product info'),
            message: t(
              'Failed to get the product info using VAN number. Please check the value entered or contact support.'
            ),
          })
        );
      }
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed Getting Company & Category data'),
          message: t(
            'Please refresh the page to try getting this data again or contact support'
          ),
        })
      );
    }
  };

  const getProductListFromCategory = async (companyName, productCategory) => {
    try {
      dispatch(updateSpinnerState(true));
      const products = await getProductsBasedOnCategoryAndCompany({
        companyName,
        productCategory,
      });
      dispatch(setMatchingProductsInStore(products));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Fetching Products Failed'),
          message: t(
            'Please refetch by changing category. If still problem persists, Please contact support'
          ),
        })
      );
      dispatch(updateSpinnerState(false));
    }
  };

  const getAllCompaniesAndCategories = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const data = await getAllCompaniesAndCategoriesData();
      dispatch(setCompanyAndCategoryDataInStore(data));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed Getting Company & Category data'),
          message: t(
            'Please refresh the page to try getting this data again or contact support'
          ),
        })
      );
    }
  };
  const addProductToRequisition = () => {
    const productItem = {
      product: formData.product,
      quantity: formData.quantity,
    };
    setRequisitionProducts([...requisitionProducts, productItem]);
  };
  const generateRequisition = async () => {
    const { companyName, productCategory, ...finalData } = formData;
    const { error: formErrors = {}, value: formValue } =
      createNewRequisitionSchema.validate(finalData, {
        abortEarly: false,
      });
    const { details = {} } = formErrors;
    if (details?.length > 0) {
      const validationErrorMessages = returnValidationErrorMessages(details);
      setFormErrors(validationErrorMessages);
    } else {
      try {
        dispatch(updateSpinnerState(true));
        await createNewRequisition({
          formValue,
          requisitionProducts,
          warehouse,
        });
        setFormErrors({});
        setFormData({});
        setRequisitionProducts([]);
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'success',
            title: t('Requisition Created'),
            message: t('Your Requisition has been created successfully'),
          })
        );
      } catch (e) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Requisition Failed'),
            message: t(e.message),
          })
        );
      }
    }
  };
  return (
    <div className="mt-1">
      <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Requisitions')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t('New')}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t('Create New Requisition')}</h4>
          <p className="mb-0">{t('Use this to create new requisitions')}</p>
        </div>
      </div>
      <Row>
        <Col sm={4}>
          <h4>{t('Invoice Details')}</h4>
          <Form.Group className="mb-4">
            <Form.Label
              className={
                formErrors?.['toBranch']?.length > 0 ? 'text-danger' : ''
              }
            >
              {t('Warehouse Name')}
            </Form.Label>
            <DropdownComponent
              onChange={(option) => {
                setFormData((oldData) => ({
                  ...oldData,
                  toBranch: option.value,
                }));
              }}
              options={returnWarehouseDropdownOptions(otherWarehouses)}
              placeholder={t('Select To branch')}
            />
          </Form.Group>
          {formErrors?.['toBranch']?.length > 0 && (
            <Form.Control.Feedback type="invalid">
              {formErrors['toBranch']}
            </Form.Control.Feedback>
          )}

          <Form.Group className="mb-4">
            <Form.Label
              className={
                formErrors?.['deliveryBranch']?.length > 0 ? 'text-danger' : ''
              }
            >
              {t('Delivery Branch')}
            </Form.Label>
            <DropdownComponent
              onChange={(option) => {
                setFormData((oldData) => ({
                  ...oldData,
                  deliveryBranch: option.value,
                }));
              }}
              options={returnWarehouseDropdownOptions(otherWarehouses)}
              placeholder={t('Select Delivery branch')}
            />
          </Form.Group>
          {formErrors?.['deliveryBranch']?.length > 0 && (
            <Form.Control.Feedback type="invalid">
              {formErrors['deliveryBranch']}
            </Form.Control.Feedback>
          )}
          {/* <Row> */}
          {/* <Col> */}
          <Form.Group className="mb-4">
            <Form.Label
              className={
                formErrors?.['requisitionType']?.length > 0 ? 'text-danger' : ''
              }
            >
              {t('Choose Requisition Type')}
            </Form.Label>
            <DropdownComponent
              onChange={(option) => {
                setFormData((oldData) => {
                  if (option.value === 'Display') {
                    return {
                      ...oldData,
                      requisitionType: option.value,
                      hoApproval: 'yes',
                    };
                  } else {
                    return {
                      ...oldData,
                      requisitionType: option.value,
                    };
                  }
                });
              }}
              options={[
                {
                  label: 'Display',
                  value: 'Display',
                },
                {
                  label: 'Stock',
                  value: 'Stock',
                },
                {
                  label: 'Delivery',
                  value: 'Delivery',
                },
                {
                  label: 'Replacement',
                  value: 'Replacement',
                },
                // , {
                //     label: "HO Approval",
                //     value: "hoApproval"
                // }
              ]}
              placeholder={t('Select Requisition Type')}
            />
          </Form.Group>
          {formErrors?.['requisitionType']?.length > 0 && (
            <Form.Control.Feedback type="invalid">
              {formErrors['requisitionType']}
            </Form.Control.Feedback>
          )}
          {/* </Col> */}
          {/* <Col> */}
          <Form.Group className="mb-4">
            <Form.Label
              className={
                formErrors?.['hoApproval']?.length > 0 ? 'text-danger' : ''
              }
            >
              {t('Choose HO Approval')}
            </Form.Label>
            <DropdownComponent
              onChange={(option) => {
                setFormData((oldData) => ({
                  ...oldData,
                  hoApproval: option.value,
                }));
              }}
              options={[
                {
                  label: 'Yes',
                  value: 'yes',
                },
                {
                  label: 'No',
                  value: 'no',
                },
              ]}
              disabled={formData?.requisitionType === 'Display'}
              placeholder={
                formData?.requisitionType === 'Display'
                  ? 'Yes'
                  : t('Select HO Approval')
              }
            />
          </Form.Group>
          {formErrors?.['hoApproval']?.length > 0 && (
            <Form.Control.Feedback type="invalid">
              {formErrors['hoApproval']}
            </Form.Control.Feedback>
          )}
          {/* </Col> */}
          {/* </Row> */}
          <Form.Label>{t('Requisition Date')}</Form.Label>
          <Datetime
            timeFormat={false}
            closeOnSelect={true}
            onChange={(value) => {
              setRequisitionDate(value);
              onFormInputChange('requisitionDate', new Date(value));
            }}
            renderInput={(props, openCalendar) => (
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="icon icon-xs"
                  />
                </InputGroup.Text>
                <Form.Control
                  isInvalid={formErrors?.['requisitionDate']?.length > 0}
                  required
                  type="text"
                  value={returnUserReadableDate(requisitionDate)}
                  placeholder="Select end: dd/mm/yyyy"
                  onFocus={openCalendar}
                />
                {formErrors?.['requisitionDate']?.length > 0 && (
                  <Form.Control.Feedback type="invalid">
                    {formErrors['requisitionDate']}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            )}
          />

          <Form.Group className="mb-3 mt-4">
            <Form.Label>{t('Remarks')}</Form.Label>
            <Form.Control
              required
              isInvalid={formErrors?.['remarks']?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange('remarks', event.target.value);
              }}
            />
            {formErrors?.['remarks']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter Remarks.')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col sm={4}>
          <h4>{t('Search Products')}</h4>
          <Row className="mt-2">
            <Col>
              <Form.Label>{t('Choose Search Mode')}</Form.Label>
              <DropdownComponent
                className="mt-2 mb-2"
                onChange={(option) => {
                  setSearchBy(option.value);
                  setSearchByText(option.label);
                }}
                options={[
                  {
                    label: 'Search by VAN No.',
                    value: 'van',
                  },
                  {
                    label: 'Search Manually',
                    value: 'manual',
                  },
                ]}
                placeholder={'Select an Option'}
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-4">
            <Col>
              {searchBy === 'van' && (
                <Row>
                  <Col sm={7}>
                    <Form.Group>
                      <Form.Control
                        placeholder="Enter VAN Number"
                        required
                        type="number"
                        onKeyDown={(event) => {
                          if (event.nativeEvent.code === 'Enter') {
                            fetchProductDetailsByVAN();
                          }
                        }}
                        onChange={(event) => {
                          setVanNo(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={5} className="d-block align-self-end mb-2">
                    <Button
                      type="button"
                      onClick={fetchProductDetailsByVAN}
                      variant="primary"
                    >
                      <FontAwesomeIcon icon={faSearch} /> &nbsp;&nbsp; Lookup
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <DropdownComponent
              disabled={isSearchByVAN}
              onChange={(option) => {
                setFormData((oldData) => ({
                  ...oldData,
                  companyName: option.value,
                }));
              }}
              options={returnCompaniesDropdownList(companies)}
              placeholder={
                formData?.product
                  ? formData?.product?.productName
                  : t('Select Company Name')
              }
            />
          </Row>
          <Row className="mt-2 mb-2">
            <DropdownComponent
              disabled={!formData.companyName || isSearchByVAN}
              className="mt-2 mb-2"
              onChange={(option) => {
                setFormData((oldData) => ({
                  ...oldData,
                  productCategory: option.value,
                }));
                getProductListFromCategory(formData.companyName, option.value);
              }}
              options={returnCategoriesDropdownList(categories)}
              placeholder={
                formData?.product
                  ? formData?.product?.productCategory
                  : t('Select Product Category')
              }
            />
          </Row>
          <Row className="mt-2 mb-2">
            <DropdownComponent
              disabled={
                !formData.companyName ||
                !formData.productCategory ||
                isSearchByVAN
              }
              className="mt-2 mb-2"
              onChange={(option) => {
                setFormData((oldData) => ({
                  ...oldData,
                  product: option.value,
                }));
              }}
              options={returnProductsDropdownList(productsList)}
              placeholder={
                formData?.product
                  ? formData?.product?.productName
                  : t('Select Product Name')
              }
            />
          </Row>
          <Row className="mt-2 mb-2">
            <DropdownComponent
              placeholder={formData?.product?.productName || 'Select Product'}
              disabled
              className="mt-2 mb-2"
              onChange={(option) => {}}
              options={[]}
            />
          </Row>
          <Row className="mt-2 mb-2">
            <Form.Group className="mb-3 mt-4">
              <Form.Label>{t('Quantity')}</Form.Label>
              <Form.Control
                required
                isInvalid={formErrors?.['quantity']?.length}
                type="number"
                onChange={(event) => {
                  onFormInputChange('quantity', event.target.value);
                }}
              />
              {formErrors?.['quantity']?.length && (
                <Form.Control.Feedback type="invalid">
                  {t('Please enter Quantity Number.')}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>
          <Row
            className="mt-2 mb-2"
            style={{
              padding: 10,
            }}
          >
            <Button
              className="w-100"
              disabled={!(formData?.product?.productName && formData.quantity)}
              type="button"
              onClick={() => addProductToRequisition()}
              variant="primary"
            >
              <FontAwesomeIcon icon={faCartPlus} />
              &nbsp;&nbsp;{t('Add to Request')}
            </Button>
          </Row>
        </Col>
        <Col sm={4}>
          <AddedProductsCardList
            height={'46vh'}
            products={requisitionProducts}
            title={'Products In Requisition'}
          />
          <Row
            className="mt-2 mb-2"
            style={{
              padding: 10,
            }}
          >
            <Button
              className="w-100"
              disabled={!requisitionProducts.length}
              type="button"
              onClick={() => generateRequisition()}
              variant="primary"
            >
              <FontAwesomeIcon icon={faCartPlus} />
              &nbsp;&nbsp;{t('Create Request')}
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default AddNewRequisition;
