import {
  SET_COMPANIES_AND_CATEGORIES_DATA_IN_STORE,
  SET_MATCHING_PRODUCTS_IN_STORE,
  SET_PRODUCTSLIST_DROPDOWN_IN_STORE,
  SET_SUB_CATEGORIES_DATA_IN_STORE,
} from './types';

export const setCompanyAndCategoryDataInStore = (data) => {
  return {
    type: SET_COMPANIES_AND_CATEGORIES_DATA_IN_STORE,
    data,
  };
};
export const setSubCategoriesDataInStore = (data) => {
  return {
    type: SET_SUB_CATEGORIES_DATA_IN_STORE,
    data,
  };
};

export const setMatchingProductsInStore = (data) => {
  return {
    type: SET_MATCHING_PRODUCTS_IN_STORE,
    data,
  };
};

export const setProductListDropdownInStore = (data) => {
  return {
    type: SET_PRODUCTSLIST_DROPDOWN_IN_STORE,
    data,
  };
};
