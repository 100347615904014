import React, { useEffect, useState } from 'react';
import { individualSchemeTable } from '../../../../utils/sms/individualSchemeTable';
import PurchaseDataModal from './PurchaseDataModal';
import { getDetailedPurchase } from '../../../../parse-functions/sms/scheme';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import TableOnly from './TableOnly';

const PurchaseData = ({ orders }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [detailedPurchaseData, setDetailedPurchaseData] = useState([]);

  const HandleCloseModal = () => {
    setShowModal(false);
  };

  const fetchDetailedPurchase = async (row) => {
    try {
      dispatch(updateSpinnerState(true));
      const data = await getDetailedPurchase({ PurchaseVNo: row.PurchaseVNo });
      setDetailedPurchaseData(data);
      dispatch(updateSpinnerState(false));
      setShowModal(true);
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Data fetch Failed',
          message: error.message,
        })
      );
    }
  };

  const handleRowClick = async (row) => {
    updateSpinnerState(true);
    await fetchDetailedPurchase(row);
  };

  return (
    <div>
      <TableOnly
        data={orders.data}
        columns={individualSchemeTable(orders?.showMore)}
        handleRowClick={orders?.showMore ? handleRowClick : () => {}}
      />
      <PurchaseDataModal
        showModal={showModal}
        closeModal={HandleCloseModal}
        data={detailedPurchaseData}
        columns={individualSchemeTable(orders?.showMore)}
      />
    </div>
  );
};

export default PurchaseData;
