import { faHome, faPeopleArrows, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Button, ButtonGroup, Col, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateSpinnerState } from "../../actions/spinner";
import { setSelectedTripInStore, setTemporaryTripsInStore } from "../../actions/trips";
import { getTemporaryTripsFromServer } from "../../parse-functions/trips";
import { convertArrayToObj } from "../../utils/json";
import TripsTableData from "./tripsTableData";
import DateRangeSelector from "../../components/common/DateRangeSelector";
import { updateToastInfo } from "../../actions/settings";
import { setFiltersInStore } from "../../actions/filters";
import { returnEndOfDay, returnStartOfDay } from "../../utils/datetime";
import ChangeLoaderInfoModal from "./updateLoaderInfoModal";
import { getLoadersListForStore } from "../../parse-functions/loaders";
import { setLoadersListInStore } from "../../actions/loaders";
import { returnTemporaryTripColumns } from "./tripsUtils";

const TemporaryTrips = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageType = "temporaryTrips";
  const temporaryTrips = useSelector((state) => state.trips.temporaryTrips || {});
  const storeInfo = useSelector((state) => state.user.warehouse || {});
  const filtersValue = useSelector((state) => state.filters?.[pageType]);
  const [showChangeLoaderModal, setShowChangeLoaderModal] = useState(false);
  const loadersList = useSelector((state) => state?.loaders?.loadersList || []);
  const isSuperAdmin = useSelector((state) => state.user.preferences.isSuperAdmin || false);

  useEffect(() => {
    if (storeInfo.warehouseCode && loadersList?.length === 0) {
      fetchLoadersListForWarehouse();
    }
  }, [storeInfo]);

  const fetchLoadersListForWarehouse = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const loadersList = await getLoadersListForStore({
        zone: storeInfo?.zone,
        storeCode: storeInfo?.warehouseCode,
        status: "active"
      });
      dispatch(setLoadersListInStore(loadersList));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
    }
  }
  const handleRowClick = (tripNumber, objectId) => {
    dispatch(setSelectedTripInStore(tripNumber));
    navigate(`/trips/temporary/${objectId}`);
  };
  const getTemporaryTrips = async (requestProps) => {
    dispatch(updateSpinnerState(true));
    const tempTrips = await getTemporaryTripsFromServer(requestProps);
    const tripsObject = convertArrayToObj(tempTrips, "tripNumber");
    dispatch(setTemporaryTripsInStore(tripsObject));
    dispatch(updateToastInfo({
      show: true,
      type: "success",
      title: "Trips fetched",
      message: "All Temporary Trips for selected date has been fetched"
    }))
    dispatch(updateSpinnerState(false));
  };
  const onDateSelect = (startDate, endDate) => {
    const requestProps = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      warehouseCode: storeInfo?.warehouseCode,
      isSuperAdmin
    };
    dispatch(setFiltersInStore({
      pageType,
      data: {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      }
    }))
    getTemporaryTrips(requestProps);
  }
  const handleGetTemporaryTrips = () => {
    getTemporaryTrips({
      startDate: filtersValue.startDate ? new Date(filtersValue.startDate) : returnStartOfDay(new Date()),
      endDate: filtersValue.endDate ? new Date(filtersValue.endDate) : returnEndOfDay(new Date()),
      warehouseCode: storeInfo?.warehouseCode,
      isSuperAdmin
    })
  };
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>{t("Trips")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Temporary")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Temporary Trips")}</h4>
          <p className="mb-0">{t("All your non-dispatched trips are listed here.")}</p>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            size="sm"
            variant="primary"
            onClick={() => {
              setShowChangeLoaderModal(true);
            }}
            style={{
              marginRight: 20
            }}>
            <FontAwesomeIcon icon={faPeopleArrows} />&nbsp;&nbsp; {t("Change Loader Details")}
          </Button>
          <ButtonGroup>
            <Button onClick={() => {
              handleGetTemporaryTrips();
            }} variant="outline-primary" size="sm">
              <FontAwesomeIcon icon={faRefresh} />&nbsp;&nbsp;{t("Refresh")}
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={6} xl={6}>
            <DateRangeSelector onSubmit={onDateSelect} filtersValue={filtersValue} />
          </Col>
        </Row>
      </div>

      <TripsTableData
        isTemporary
        columns={returnTemporaryTripColumns()}
        data={Object.values(temporaryTrips)}
        onRowClick={handleRowClick}
      />
      <ChangeLoaderInfoModal
        showModal={showChangeLoaderModal}
        closeModal={() => {
          setShowChangeLoaderModal(false);
        }}
      />
    </>
  )
}
export default TemporaryTrips;