import React from 'react';
import TableOnly from '../../../scheme-management/Components/IndividualScheme.js/TableOnly';
import { generateRandomId } from '../../../../utils/string';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@themesberg/react-bootstrap';
import { infoModalColumns } from '../utils';

const InfoModal = ({
  showModal,
  closeModal,
  modalTitile,
  data = [],
  columns,
  size = 'md',
}) => {
  console.log(data);

  const { t } = useTranslation();
  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size={size}
    >
      <Modal.Header>
        <Modal.Title className="h6">{t(modalTitile)}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {/* <div className="d-flex flex-wrap h-50">
          <ul>
            {data.length > 0 &&
              data?.map((item, index) => <li key={index}>{item}</li>)}
          </ul>
        </div> */}
        <TableOnly data={data} columns={columns} />
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;
