import React from "react";
import { Badge } from "@themesberg/react-bootstrap";
import { getTableRowCheckBox } from "../table";
import { getTripBadgeStatus } from "../trips";

export const usersTableColumns = (onRowSelect, type, navigate) => {
  const columns = [
    {
      dataField: "objectId",
      text: "Select",
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },

    {
      dataField: "name",
      text: "NAME",
    },
    {
      dataField: "emailId",
      text: "EMAIL ID",
    },
    {
      dataField: "phoneNumber",
      text: "PHONE NUMBER",
    },
    {
      dataField: "role",
      text: "User Role",
    },
    {
      dataField: "status",
      text: "STATUS",
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <Badge bg={getTripBadgeStatus(cell)} className="badge-md">
              {cell}
            </Badge>
          </div>
        );
      },
    },
  ];
  return columns;
};
