import { Button, Card } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import ExcelDownloadReport from '../../../components/common/excel/download';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight, faSync } from '@fortawesome/free-solid-svg-icons';
import { StyledSearchBar } from '../../trips/styles';
import { styles } from './style';
import TableOnly from '../../scheme-management/Components/IndividualScheme.js/TableOnly';
import { infoModalColumns, inventorySyncColumns } from './utils';
import ManualSyncModal from './components/ManualSyncModal';
import { useDispatch } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { apiCall } from '../../../middlewares/api';
import { returnUserReadableDateTime } from '../../../utils/datetime';
import InfoModal from './components/InfoModal';

const InventorySync = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [logs, setLogs] = useState({});
  const [manualSyncModal, setManualSyncModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoData, setInfoData] = useState({});
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const handleshowManualModal = () => {
    setSelectedType(null);
    setSelectedBrands([]);
    setManualSyncModal(true);
  };

  const handleShowInfoModal = (data, title) => {
    const arrayOfObjects = data?.map((each) => ({ [title]: each }));
    setInfoData({ title: title, data: arrayOfObjects });
    setShowInfoModal(true);
  };

  const fetchData = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall('get', '/internal/inventory/syncData');
      setLogs(response);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed',
          message: error.message,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="mt-4 d-flex justify-content-between">
        <div className="font-weigth-medium fs-4">Inventory Sync Overview</div>
        <div className="d-flex gap-3">
          <div>
            <Button
              variant="white"
              size="md"
              className="btn-export"
              onClick={handleshowManualModal}
            >
              <FontAwesomeIcon icon={faSync} />
              &nbsp;&nbsp; {t('Manual Sync')}
            </Button>
          </div>
          <div>
            <Button
              variant="white"
              size="md"
              className="btn-export"
              onClick={fetchData}
            >
              <FontAwesomeIcon icon={faRotateRight} />
              &nbsp;&nbsp; {t('Refresh')}
            </Button>
          </div>
          <div>
            <ExcelDownloadReport
              data={logs?.result || []}
              fileName="inventorySync"
            />
          </div>
        </div>
      </div>

      <Card className="my-2">
        <Card.Body>
          <div className="d-flex justify-content-evenly gap-3 pt-2">
            <div style={styles.filters}>
              <p style={styles.filterTitle}>{t('Last Sync')}</p>
              <StyledSearchBar
                width={'100%'}
                heigth={'45px'}
                type={'text'}
                placeholder={returnUserReadableDateTime(logs?.lastSynced) || ''}
                disabled={true}
              />
            </div>
            <div style={styles.filters}>
              <p style={styles.filterTitle}>{t('Next Scheduled Sync')}</p>
              <StyledSearchBar
                width={'100%'}
                heigth={'45px'}
                type={'text'}
                placeholder={returnUserReadableDateTime(logs?.nextSync) || ''}
                disabled={true}
              />
            </div>
            <div style={styles.filters}>
              <p style={styles.filterTitle}>{t('Sync Frequncy')}</p>
              <StyledSearchBar
                width={'100%'}
                heigth={'45px'}
                type={'text'}
                placeholder={'Daily'}
                disabled={true}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      <TableOnly
        data={logs?.result || []}
        columns={inventorySyncColumns(handleShowInfoModal)}
      />
      <ManualSyncModal
        showModal={manualSyncModal}
        closeModal={() => {
          setManualSyncModal(false);
          if (isApiCalled) {
            fetchData();
          }
          setIsApiCalled(false);
        }}
        data={logs?.result?.[0] || {}}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        setIsApiCalled={setIsApiCalled}
      />
      {showInfoModal && (
        <InfoModal
          showModal={showInfoModal}
          closeModal={() => setShowInfoModal(false)}
          modalTitile={infoData?.title || ''}
          data={infoData?.data || []}
          columns={infoModalColumns(infoData?.title)}
        />
      )}
    </>
  );
};

export default InventorySync;
