import { Button, Form, Modal, InputGroup } from '@themesberg/react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateRandomId } from '../../utils/string';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { returnUserReadableDate } from '../../utils/datetime';
import RejectDropdown from './RejectDropdown';
import EditableDropDown from './EditableDropdown';

const ConfirmationModal = (props) => {
  const { t } = useTranslation();
  const {
    showModal,
    closeModal,
    modalBody,
    modalTitle,
    onConfirm,
    confirmationModalProps,
    setConfirmationModalProps,
  } = props;

  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title className="h6">{t(modalTitle)}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {t(modalBody)}
        <br />
        <br />
        {confirmationModalProps?.map((item, index) => (
          <>
            {item?.type === 'date' && (
              <>
                {t(`${item.title} :`)}
                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={(value) => {
                    setConfirmationModalProps((prev) =>
                      prev.map((prevItem, i) =>
                        i === index
                          ? { ...prevItem, value: new Date(value) }
                          : prevItem
                      )
                    );
                  }}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          className="icon icon-xs"
                        />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={returnUserReadableDate(item.value)}
                        placeholder="Select date: dd/mm/yyyy"
                        onFocus={openCalendar}
                      />
                    </InputGroup>
                  )}
                />
              </>
            )}
            {item?.type === 'textArea' && (
              <Form.Group>
                {t(`${item.title} :`)}
                <Form.Control
                  id={generateRandomId()}
                  value={item.value}
                  onChange={(e) => {
                    const { value } = e.target;
                    setConfirmationModalProps((prev) =>
                      prev.map((prevItem, i) =>
                        i === index ? { ...prevItem, value: value } : prevItem
                      )
                    );
                  }}
                  rows={2}
                  as="textarea"
                />
              </Form.Group>
            )}
            {item?.type === 'text' && (
              <Form.Group>
                {t(`${item.title} :`)}
                <Form.Control
                  id={generateRandomId()}
                  value={item.value}
                  onChange={(e) => {
                    const { value } = e.target;
                    setConfirmationModalProps((prev) =>
                      prev.map((prevItem, i) =>
                        i === index ? { ...prevItem, value: value } : prevItem
                      )
                    );
                  }}
                  type={item.type}
                />
              </Form.Group>
            )}
            {item?.type === 'dropdown' && (
              <Form.Group>
                {t(`${item.title} :`)}
                <EditableDropDown
                  isMultiSelect={item.isMulti}
                  onChange={(option) => {
                    const { value } = option;
                    setConfirmationModalProps((prev) =>
                      prev.map((item, i) =>
                        i === index ? { ...item, value: value } : item
                      )
                    );
                  }}
                  value={item.value}
                  options={item.options}
                  placeholder="Select Value from below"
                />
              </Form.Group>
            )}
            {item?.type === 'rejectComponent' && (
              <RejectDropdown
                options={item.options}
                setReason={(value) =>
                  setConfirmationModalProps((prev) =>
                    prev.map((prevItem, i) =>
                      i === index ? { ...prevItem, value: value } : prevItem
                    )
                  )
                }
                setRemarks={(value) =>
                  setConfirmationModalProps((prev) =>
                    prev.map((prevItem, i) =>
                      i === index ? { ...prevItem, remarks: value } : prevItem
                    )
                  )
                }
                reason={item.value}
                remarks={item.remarks}
                position={item.position}
              />
            )}
          </>
        ))}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onConfirm}
          disabled={
            confirmationModalProps &&
            confirmationModalProps.some((item) => !item.value)
          }
        >
          {t('Confirm')}
        </Button>
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={closeModal}
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmationModal;
