import { returnUserReadableDateTime } from "./datetime";
import { loadersFieldMap } from "./loaders";
import { generateRandomId } from "./string";
import { orderStatusMap } from "./constants";
import { invoiceNumberValidation } from "./planning";

export const ignoreColumnsInTotalForDeliveryData = ["branchName", "Vehicle Number", "vehicleModel", "Vehicle Vendor", "Driver Name", "driverContactNumber", "vehicleArea", "Loader Name 1", "Loader Number 1", "Loader Name 2", "Loader Number 2", "Loader Name 3", "Loader Number 3", "Loader Name 4", "Loader Number 4", "Loader Name 5", "Loader Number 5", "Temposheet Number"];
export const excelSheetMapping = {
    customerName: "Customer Name",
    invoiceNo: "Invoice Number",
    contactNumber: "Contact Number",
    customerCode: "Customer Code",
    shippingAddress1: "Shipping Address 1",
    shippingAddress2: "Shipping Address 2",
    shippingAddress3: "Shipping Address 3",
    shippingPincode: "Shipping Pincode",
    email: "Email",
    targetBranch: "Target Branch",
    CGST: "CGST",
    IGST: "IGST",
    SGST: "SGST",
    amount: "Amount",
    gstAmount: "GST Amount",
    gstAssessableValue: "GST Value",
    gstProductCategory: "GST Product Category",
    gstRate: "GST Rate",
    gstin: "GSTIN",
    branch: "Branch",
    rate: "Rate",
    brand: "Brand",
    product: "Product",
    serialNo: "Serial No",
    productCategory: "Product Category",
    itemNumber: "Item Number",
    productCode: "Product Code",
    quantity: "Quantity",
    buyback: "Buyback",
    buybackProduct: "Buyback Product",
    status: "Status",
    createdBy: "Created By",
    dashboardStatus: "Dashboard Status",
    dashboardSubStatus: "Dashboard Substatus",
    mobileStatus: "Mobile Status",
    mobileSubStatus: "Mobile SubStatus",
    deliveryWarehouse: "Delivery Warehouse",
    isDisplay: "Is Display / Transfer",
    tripNumber: "Temposheet Number",
    vehicleNumber: "Vehicle Number",
    vehicleType: "Vehicle Type",
    vendorName: "Vehicle Vendor",
    driverName: "Driver Name",
    loaderName1: "Loader Name 1",
    loaderName2: "Loader Name 2",
    loaderName3: "Loader Name 3",
    loaderName4: "Loader Name 4",
    loaderName5: "Loader Name 5",
    loaderNumber1: "Loader Number 1",
    loaderNumber2: "Loader Number 2",
    loaderNumber3: "Loader Number 3",
    loaderNumber4: "Loader Number 4",
    loaderNumber5: "Loader Number 5",
    mobileNo: "Mobile No",
    pickUpLocation: "Pickup Location",
    priorityDelivery: "Priority Delivery",
    receiveScanWarehouse: "Received Scan Warehouse",
    receivedBy: "Received By",
    scannedBy: "Scanned By",
    ...orderStatusMap
};

export const returnDownloadableData = (data) => {
    return data?.map(rowData => {
        const rowItem = {};
        Object.keys(rowData).forEach(columnName => {
            rowItem[excelSheetMapping[columnName] || columnName] = rowData[columnName];
        });
        return rowItem;
    });
};
/* eslint-disable no-unused-expressions */
export const convertExcelDateToComputerDate = (date, returnActualDate = false) => {
    let inputDate = date;
    if (typeof date === "string") {
        // read this line of date and split
        const [date, month, year] = inputDate?.split("-");
        if (date && month && year) {
            return new Date(`${year}-${month}-${date}`);
        } else {
            return new Date();
        }
    }
    let converted_date = new Date(Math.round((date - 25569) * 864e5));
    if(returnActualDate) {
        return converted_date;
    }
    converted_date = String(converted_date).slice(4, 15)
    return converted_date;
}

export const generateDisplayDataFromExcel = (excelData, branchData) => {
    const mappedData = [];
    excelData.forEach((row) => {
        const branchName = row['To Branch'];
        mappedData.push({
            objectId: generateRandomId(10),
            date: convertExcelDateToComputerDate(row["Date"]),
            invoiceDate: convertExcelDateToComputerDate(row["Date"]),
            invoiceNo: row['Voucher No'],
            orderNo: row['Voucher No'],
            branch: row['Branch'],
            targetBranch: row['To Branch'],
            customerCode: row['To Branch'],
            customerName: row['To Branch'],
            productCode: row["Product"],
            itemNumber: row["Product"],
            product: row["Product"],
            serialNo: row["Serial No"],
            buyback: false,
            buybackProduct: "",
            quantity: row["Quantity Issued"],
            rate: row["Rate"],
            amount: row["Amount"],
            billingAddress1: branchData[branchName]?.address || row["To Branch"],
            billingAddress2: "",
            billingAddress3: "",
            email: "",
            mobileNo: "",
            billingPincode: branchData[branchName]?.pincode || "-",
            shippingAddress1: branchData[branchName]?.address || row["To Branch"],
            shippingAddress2: "",
            shippingAddress3: "",
            shippingPincode: branchData[branchName]?.pincode || "-",
            shippingMobileNo: "",
            brand: row["Product"],
            productCategory: row["Product"],
            selfPickup: false,
            gstProductCategory: "-",
            gstAssessableValue: "-",
            CGST: "",
            SGST: "",
            IGST: "",
            gstAmount: "",
            gstRate: "",
            gstin: "",
            priorityDelivery: false,
            pickUpLocation: row['Branch'],
            deliveryWarehouse: row['To Branch'],
            status: "pending",
            isDisplay: true,
            zone: branchData[branchName]?.zone
        });
    });
    return mappedData;
};

export const convertExcelDataToJSONRows = (columns, rows, convertFromExcelDate = []) => {
    const data = [];
    rows?.forEach(row => {
        const dataRow = {};
        columns?.forEach((colName, index) => {
            if (row[index] && row[index] !== undefined) {
                dataRow[colName] = convertFromExcelDate.indexOf(colName)> -1 ?  convertExcelDateToComputerDate(row[index], true): row[index];
            }
        });
        if (dataRow && Object.keys(dataRow)?.length > 0) {
            data.push(dataRow);
        }
    });
    return data;
}

export const missingBranchesData = (data, branchInfo) => {
    const missingBranches = {};
    data.forEach((invoice) => {
        if (!branchInfo[invoice["To Branch"]]) {
            missingBranches[invoice["To Branch"]] = 1
        }
    });
    return Object.keys(missingBranches);
}

export const returnAnalysisDownloadFileData = (data) => {
    const columnsNeeded = ["customerName", "shippingAddress1", "shippingAddress2", "shippingAddress3", "mobileNo", "invoiceNo", "product", "productCategory", "productCode", "serialNo", "invoiceNo", "status", "subStatus", "failureReason", "remarks", "branch", "brand", "targetBranch", "vehicleNumber", "vehicleArea", "dispatchedAt", "closedAt", "closedBy", "driverName", "driverContactNo", "temposheetNo"]
    const finalData = [];
    data?.forEach((row) => {
        finalData.push({
            "customerName": row?.customerName,
            "shippingAddress1": row?.shippingAddress1,
            "shippingAddress2": row?.shippingAddress2,
            "shippingAddress3": row?.shippingAddress3,
            "mobileNo": row?.mobileNo,
            "invoiceNo": row?.invoiceNo,
            "product": row?.product,
            "productCategory": row?.productCategory,
            "productCode": row?.productCode,
            "serialNo": row?.serialNo,
            "status": row?.dashboardStatus || row?.status || row?.mobileStatus,
            "subStatus": row?.dashboardSubStatus || row?.mobileSubStatus,
            "failureReason": row?.failureReason,
            "remarks": row?.remarks,
            "branch": row?.branch,
            "brand": row?.brand,
            "targetBranch": row?.targetBranch,
            "vehicleNumber": row?.tripInfo?.vehicleNumber,
            "vehicleArea": row?.tripInfo?.vehicleArea,
            "dispatchedAt": row?.tripInfo?.dispatchedAt ? returnUserReadableDateTime(row?.tripInfo?.dispatchedAt) : "-",
            "closedAt": row?.tripInfo?.closedAt ? returnUserReadableDateTime(row?.tripInfo?.closedAt) : "-",
            "closedBy": row?.tripInfo?.closedBy,
            "driverName": row?.tripInfo?.driverInfo?.driverName || "-",
            "driverContactNo": row?.tripInfo?.driverInfo?.contactNumber || "-",
            "temposheetNo": row?.tripNumber
        })
    })
    return {
        data: finalData,
        headers: columnsNeeded
    };
}

export const convertdownloadDataToFlatJSON = (data) => {
    const flatJSON = [];
    try {
        data?.forEach(row => {
            if (Object.keys(row)?.length === 0) {
                return
            }
            let dataRow = {};
            Object.keys(row)?.forEach(rowKeyName => {
                if (typeof row[rowKeyName] === "object") {
                    if (row[rowKeyName]?.length || rowKeyName === "parcelUpdatedAt") {
                        dataRow[rowKeyName] = row[rowKeyName];
                    } else {
                        row?.[rowKeyName] && Object.keys(row?.[rowKeyName])?.forEach(internalKey => {
                            if (typeof row?.[rowKeyName]?.[internalKey] === "object") {
                                if (row?.[rowKeyName]?.[internalKey]?.length) {
                                    dataRow[internalKey] = row?.[rowKeyName]?.[internalKey];
                                } else {
                                    dataRow = {
                                        ...dataRow,
                                        ...row?.[rowKeyName]?.[internalKey]
                                    }
                                }

                            } else {
                                dataRow[internalKey] = row?.[rowKeyName]?.[internalKey];
                            }
                        });
                    }
                } else if (typeof row[rowKeyName] === "boolean") {
                    dataRow[rowKeyName] = row[rowKeyName] ? "True" : "False"
                } else {
                    dataRow[rowKeyName] = row[rowKeyName];
                }
            });
            flatJSON.push(dataRow);
        });
    } catch (e) {
        console.log(e);
    }
    return flatJSON
}
export const returnDownloadFileData = (data) => {
    let headers = [];
    if (data?.length > 0) {
        headers = Object.keys(data[0]);
    }
    return {
        data,
        headers
    }
};

export const convertExcelDataToJSONForLoaders = (columns, rows, zone) => {
    const data = [];
    rows?.forEach(row => {
        const dataRow = {};
        columns?.forEach((colName, index) => {
            dataRow[loadersFieldMap[colName]] = row[index];
        });
        if (Object.keys(dataRow)?.length > 0) {
            dataRow.zone = zone;
            data.push(dataRow);
        }
    });
    return data;
}

export const returnTotalRowFromData = (data, colsToIgnore, isAttendance = false) => {
    const totalRow = {};
    data?.forEach(row => {
        Object.keys(row)?.forEach(colName => {
            if (colsToIgnore.indexOf(colName) < 0) {
                if (!totalRow[colName]) {
                    totalRow[colName] = 0;
                }
                totalRow[colName] += isNaN(Number(row[colName])) ? isAttendance && row[colName] === "P" ? 1 : 0 : Number(row[colName])
            }
        });
    });
    return totalRow
}

export const validateUploadedInvoiceNumbers = (data) => {
    let allInvoiceNumbersAreValid = true;
    data?.forEach(invoiceItem => {
        if (!invoiceNumberValidation(invoiceItem["Voucher No"])) {
            allInvoiceNumbersAreValid = false
        }
    })
    return allInvoiceNumbersAreValid;
}