import React, { useEffect, useState } from 'react';
import {
  faArrowRotateLeft,
  faBroom,
  faRotateRight,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Form } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import ExcelDownloadReport from '../../../components/common/excel/download';
import EditableDropDown from '../../../components/common/EditableDropdown';
import TableOnly from '../../scheme-management/Components/IndividualScheme.js/TableOnly';
import { bikerAnalysisColumns } from './utils';
import { updateUserStatus } from '../../../parse-functions/sms/addUsers';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../../actions/settings';
import { updateSpinnerState } from '../../../actions/spinner';
import ConfirmationModal from '../../../components/common/confirmationModal';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { getDeliveryAgentsRegionUsers } from '../../../parse-functions/oms/deliveryAgent';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';

const BikerAnalysis = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  // const [bikers, setBikers] = useState([]);
  const [deliveryAgents, setDeliveryAgents] = useState({});
  const [data, setData] = useState([]);
  const [selectedBiker, setSelectedBiker] = useState('');
  const [isValidInput, setIsValidInput] = useState(true);
  const [flag, setFlag] = useState(false);
  const [selected, setSelected] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const filterObj = [
    {
      label: 'SELECTED BIKER',
      value: selectedBiker,
      func: setSelectedBiker,
    },
    { label: 'START DATE', value: startDate, func: setStartDate },
    { label: 'END DATE', value: endDate, func: setEndDate },
  ];

  const handleEmpty = async (fun) => {
    fun(() => '');
    // await fetchData();
    setFlag((flag) => !flag);
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setSelectedBiker('');
    setFlag((flag) => !flag);
  };

  const mockData = [
    {
      objectId: '6721',
      driverName: 'driver_14',
      avgDeliveriesPerDay: '6',
      avgTimePerDeliery: '45 mins',
      deliveriesCompleted: '23',
      deliveriesRejected: '12',
      lastLocation: 'FC Road, Shivajinagar, Pune',
      deliveriesAssgined: '35',
      status: 'active',
    },
    {
      objectId: '4893',
      driverName: 'driver_87',
      avgDeliveriesPerDay: '4',
      avgTimePerDeliery: '33 mins',
      deliveriesCompleted: '17',
      lastLocation: 'FC Road, Shivajinagar, Pune',
      deliveriesRejected: '3',
      deliveriesAssgined: '20',
      status: 'active',
    },
    {
      objectId: '1529',
      driverName: 'driver_56',
      avgDeliveriesPerDay: '8',
      avgTimePerDeliery: '22 mins',
      lastLocation: 'FC Road, Shivajinagar, Pune',
      deliveriesCompleted: '50',
      deliveriesRejected: '9',
      deliveriesAssgined: '59',
      status: 'active',
    },
    {
      objectId: '8970',
      driverName: 'driver_23',
      avgDeliveriesPerDay: '7',
      avgTimePerDeliery: '18 mins',
      lastLocation: 'FC Road, Shivajinagar, Pune',
      deliveriesCompleted: '40',
      deliveriesRejected: '2',
      deliveriesAssgined: '42',
      status: 'active',
    },
    {
      objectId: '3845',
      driverName: 'driver_41',
      avgDeliveriesPerDay: '3',
      avgTimePerDeliery: '50 mins',
      lastLocation: 'FC Road, Shivajinagar, Pune',
      deliveriesCompleted: '29',
      deliveriesRejected: '11',
      deliveriesAssgined: '40',
      status: 'active',
    },
    {
      objectId: '5643',
      driverName: 'driver_72',
      avgDeliveriesPerDay: '5',
      avgTimePerDeliery: '42 mins',
      lastLocation: 'FC Road, Shivajinagar, Pune',
      deliveriesCompleted: '36',
      deliveriesRejected: '5',
      deliveriesAssgined: '41',
      status: 'active',
    },
    {
      objectId: '2846',
      driverName: 'driver_99',
      avgDeliveriesPerDay: '2',
      avgTimePerDeliery: '58 mins',
      lastLocation: 'FC Road, Shivajinagar, Pune',
      deliveriesCompleted: '9',
      deliveriesRejected: '4',
      deliveriesAssgined: '13',
      status: 'active',
    },
    {
      objectId: '9134',
      driverName: 'driver_65',
      avgDeliveriesPerDay: '9',
      avgTimePerDeliery: '29 mins',
      lastLocation: 'FC Road, Shivajinagar, Pune',
      deliveriesCompleted: '48',
      deliveriesRejected: '6',
      deliveriesAssgined: '54',
      status: 'active',
    },
    {
      objectId: '6782',
      driverName: 'driver_31',
      avgDeliveriesPerDay: '10',
      avgTimePerDeliery: '20 mins',
      lastLocation: 'FC Road, Shivajinagar, Pune',
      deliveriesCompleted: '55',
      deliveriesRejected: '8',
      deliveriesAssgined: '63',
      status: 'active',
    },
    {
      objectId: '2341',
      driverName: 'driver_11', //bikers table
      avgDeliveriesPerDay: '1',
      avgTimePerDeliery: '60 mins',
      lastLocation: 'FC Road, Shivajinagar, Pune', //dailyLog table
      deliveriesCompleted: '5',
      currentlyPicked: '1',
      deliveriesRejected: '1',
      deliveriesAssgined: '6',
      status: 'active', //bikers table
    },
  ];

  const fetchData = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const respone = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/deliveryAgent/analysis',
        {
          startDate: startDate
            ? startDate
            : new Date().toISOString().split('T')[0],
          endDate: endDate ? endDate : new Date().toISOString().split('T')[0],
          biker: selectedBiker,
        }
      );
      setData(respone);
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed',
          message: e.message,
        })
      );
    }
  };
  const handleModalOnConfirmClick = async () => {
    try {
      let data = {
        id: [selected.id],
        status: selected?.action === 'active' ? 'active' : 'inactive',
      };
      console.log(data);

      dispatch(updateSpinnerState(true));
      // await updateUserStatus(data);
      // getDeliveryAgentsList();
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'User Update Successful',
          message: '',
        })
      );
      setShowDeleteModal(false);
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'User Update Failed',
          message: e.message,
        })
      );
    }
  };

  const actionClicked = (action, id) => {
    setSelected({ action, id });
    setShowDeleteModal(true);
  };

  const fetchDeliveryAgents = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await getDeliveryAgentsRegionUsers();
      if (Object.keys(response).length > 0) {
        setDeliveryAgents(response);
        // setShowAssignModal(true);
      } else {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: '',
            message: 'No agents are available for this region!',
          })
        );
      }
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: '',
          message: 'No agents are available for this region!',
        })
      );
    }
  };

  useEffect(() => {
    fetchDeliveryAgents();
  }, []);

  return (
    <>
      <h4 className="mt-2">{t('Delivery Personnel Performance and Status')}</h4>
      <p className="mt-2">{t('Please use filters to fetch data')}</p>
      <div className="d-flex justify-content-end gap-3 mb-3">
        <Button
          variant="white"
          size="md"
          className="btn-export"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <FontAwesomeIcon icon={faSliders} />
          &nbsp;&nbsp;{t('Filter')}
        </Button>
        <Button
          variant="white"
          size="md"
          className="btn-export"
          onClick={fetchData}
        >
          <FontAwesomeIcon icon={faRotateRight} />
          &nbsp;&nbsp; {t('Refresh')}
        </Button>
        <ExcelDownloadReport data={[]} fileName="SentIBT" />
      </div>
      <>
        <div
          className="table-settings mt-2 mb-4"
          style={{
            background: '#EAEDF2',
            maxHeight: openFilter ? '700px' : '0px',
            overflow: openFilter ? '' : 'hidden', // Ensure content is hidden when collapsed
            transition: 'max-height 0.7s ease-in-out', // Smooth transition when toggling
          }}
        >
          <div style={{ gap: 8, display: 'flex' }}>
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)', // Three columns
                gap: '20px', // Gap between elements
                width: '100%',
                backgroundColor: 'white',
                padding: 20,
              }}
            >
              <div>
                <Form.Label>BIKERS</Form.Label>
                <EditableDropDown
                  id={'bikers'}
                  onChange={(e) => setSelectedBiker(e.value)}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  value={selectedBiker}
                  options={arrayToDropdownOptions(Object.keys(deliveryAgents))}
                  placeholder={'Choose the Biker'}
                />
              </div>

              <div>
                <Form.Label>START DATE</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  type="date"
                  value={startDate}
                  defaultValue={null}
                  onChange={(event) => {
                    setIsValidInput(true);
                    setStartDate(event.target.value);
                  }}
                  placeholder="Start Date"
                />
              </div>

              <div>
                <Form.Label>END DATE</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  value={endDate}
                  type="date"
                  // defaultValue={searchData}
                  onChange={(event) => {
                    setEndDate(event.target.value);
                  }}
                  placeholder="End Date"
                />
              </div>

              <div
                style={{
                  gridColumn: 'span 3',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <Button
                  variant="white"
                  className="fa-export"
                  onClick={handleReset}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    background: '#EAEDF2',
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRotateLeft} />
                  &nbsp;&nbsp;{t('Reset')}
                </Button>

                <Button
                  variant="white"
                  className="fa-export"
                  onClick={fetchData}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    border: '1px solid #262B40',
                  }}
                >
                  <FontAwesomeIcon icon={faSliders} />
                  &nbsp;&nbsp;{t('Apply Filter')}
                </Button>
              </div>
            </Form.Group>
          </div>
        </div>
        {!openFilter &&
          (startDate != '' || endDate != '' || selectedBiker != '') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                className="gap-2"
                style={{
                  display: 'flex',
                  width: '100%',
                  background: '#F5F8FB',
                  padding: 20,
                }}
              >
                {filterObj.map((each) => {
                  if (each.value != '') {
                    return (
                      <div>
                        <Form.Label style={{ color: '#262B4099' }}>
                          {each.label}
                        </Form.Label>
                        <Badge
                          variant="light"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '188px',
                            height: '41px',
                            background: 'white',
                            borderRadius: '2px',
                            cursor: 'pointer',
                            color: '#262B40',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '16.41px',
                          }}
                        >
                          {each.value}
                          <span
                            onClick={() => handleEmpty(each.func)}
                            style={{
                              color: '#262B40',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '16.41px',
                            }}
                          >
                            &times;
                          </span>
                        </Badge>
                      </div>
                    );
                  }
                })}
              </div>
              <Button
                variant="primary"
                className="fa-export"
                onClick={handleReset}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                }}
              >
                <FontAwesomeIcon icon={faBroom} />
                &nbsp;&nbsp;{t('Clear')}
              </Button>
            </div>
          )}
      </>
      <TableOnly
        data={mockData || []}
        columns={bikerAnalysisColumns(actionClicked)}
        isFooterRequired={true}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowDeleteModal(false);
        }}
        modalBody={t(
          `Are you sure you want to ${selected?.action} these users?`
        )}
        modalTitle={t(`${selected?.action} Users`)}
      />
    </>
  );
};

export default BikerAnalysis;
