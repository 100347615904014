import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import Timeline from './Timeline';
import { apiCall } from '../../../../middlewares/api';
import TableOnly from '../../../scheme-management/Components/IndividualScheme.js/TableOnly';
import {
  getBreadCrumbRoute,
  orderDetailsColumns,
  showShippingLabelDownload,
} from './utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../../actions/spinner';
import { updateToastInfo } from '../../../../actions/settings';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Nav,
  Row,
  Tab,
} from '@themesberg/react-bootstrap';
import { orderStatus } from '../../../../constants/orderStatus';
import {
  faBox,
  faClapperboard,
  faDownload,
  faFileInvoice,
  faPerson,
  faReceipt,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import BreadCrumbComponent from '../../../../components/common/Breadcrumb';
import { addTwoHoursOrNextDay, remainingTime } from '../utils';
import CountdownTimer from './CountdownTimer';
import { apiCallConsts } from '../../../../constants/apiCallConsts';
import { Routes } from '../../../../router/routes';
import { returnUserReadableDateTime } from '../../../../utils/datetime';
import {
  faCreditCard,
  faRectangleList,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import PaymentDetailsModal from './PaymentDetailsModal';
const getBase64ImageFromURL = async (url) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous'; // Resolve CORS issues if any
  img.src = url;

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL('image/jpeg'); // Convert the image to base64 (JPEG format)
      resolve(dataURL);
    };
    img.onerror = (error) => reject(error);
  });
};
const OrderDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [orderDetails, setOrderDetails] = useState([]);
  const [activeTab, setActiveTab] = useState('currentStatus');
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const currStatus = orderDetails[0]?.status;
  const isReadyForPickup = currStatus === orderStatus.readyForPickup || false;
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const navigate = useNavigate();
  const isReverse = orderDetails[0]?.serviceType === 'reverse' || false;
  const breadCrumbItems =
    orderDetails[0]?.isHyperLocal &&
    (currStatus === orderStatus.invoiced ||
      currStatus === orderStatus.readyForPickup)
      ? arrayToBreadCrumbs([
          ['Manual Assign', Routes.OMS.DeliveryAgents.ManualAssign.path],
          [`${id}`],
        ])
      : arrayToBreadCrumbs([
          [currStatus, getBreadCrumbRoute(currStatus, isReverse)],
          [`${id}`],
        ]);

  const handleDownload = async (url) => {
    const doc = new jsPDF();
    const images = [
      ...orderDetails[0]?.podImages,
      orderDetails[0]?.customerSignature,
    ];

    await Promise.all(
      images.map(async (each) => {
        const base64Image = await getBase64ImageFromURL(each);

        const imgWidth = 180;
        const imgHeight = 150;
        const position = 10;

        doc.addImage(base64Image, 'JPEG', 10, position, imgWidth, imgHeight);

        doc.addPage();
      })
    );

    doc.save(`POD${orderDetails}.pdf`);
  };

  const fetchOrderDetails = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const data = await apiCall(
        apiCallConsts.GET_METHOD,
        `${apiCallConsts.ORDER_DETAILS_URL}${id}`,
        { isAdmin: isAdmin }
      );
      setOrderDetails(data);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('failed to fetch order details'),
          message: t(`${error.message}`),
        })
      );
    }
  };
  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const handleClick = async (type) => {
    let url = '';
    try {
      if (type == 'invoice') {
        const res = await apiCall(
          apiCallConsts.GET_METHOD,
          `${apiCallConsts.INVOICE_DOWNLOAD_URL}`,
          {
            invoiceNo: orderDetails[0]?.invoiceNo,
          }
        );

        url = res?.url;
        console.log(url);
      } else if (type == 'ewaybill') {
      } else {
        url = orderDetails[0]?.shippingLabelURL;
        const response = await fetch(url);
        const labelBlob = await response.blob();
        const labelURL = URL.createObjectURL(labelBlob);
        const printWindow = window.open(labelURL, '_blank');
        printWindow.onload = () => {
          printWindow.print();
        };
      }
      if (!url) {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t(''),
            message: t(`failed to Print ${type}`),
          })
        );
        return;
      }

      await apiCall(
        apiCallConsts.PUT_METHOD,
        `${apiCallConsts.ORDER_UPDATE_URL}/shippingLabel`,
        {
          invoiceNo: orderDetails[0]?.invoiceNo,
        }
      );

      const printWindow = window.open(url, '_blank');
      printWindow.onload = () => {
        printWindow.print();
      };

      // setTimeout(() => {
      //   printWindow.close();
      // }, 5000);

      // window.open(orderDetails[0]?.shippingLabelURL, "_blank");

      fetchOrderDetails();
    } catch (error) {
      console.error(error);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('failed to Print shipping label'),
          message: t(`${error.message}`),
        })
      );
    }
  };

  let time = remainingTime(orderDetails[0]?.expiresAt?.iso);

  return (
    <>
      <>
        {orderDetails[0] && (
          <>
            <div className="mt-3">
              <BreadCrumbComponent items={breadCrumbItems} />
            </div>
            <div className="d-flex justify-content-between  align-items-center">
              <div className="d-flex gap-3  align-items-center">
                <span className="font-weigth-medium font-size-xl">
                  Order No :{' '}
                  {t(orderDetails[0]?.orderNo ? orderDetails[0]?.orderNo : '')}
                </span>
              </div>
            </div>
            <div className="d-flex gap-3 text-center my-2">
              <div className="bg-white px-3 py-1">
                <span className="fw-bold fs-6">Placed On : </span>
                <span className="font-size-sm">
                  {returnUserReadableDateTime(orderDetails[0]?.createdAt)}
                </span>
              </div>
              <div className="bg-white px-3 py-1">
                <span className="fw-bold">Last Updated : </span>
                <span className="font-size-sm">
                  {returnUserReadableDateTime(orderDetails[0]?.updatedAt)}
                </span>
              </div>
              {!isReverse && orderDetails[0]?.serialNo && (
                <div className="bg-white px-3 py-1">
                  <span className="fw-bold">Serial No : </span>
                  <span className="font-size-sm">
                    {orderDetails[0]?.serialNo}
                  </span>
                </div>
              )}
              {orderDetails[0]?.invoiceNo && (
                <div className="bg-white px-3 py-1">
                  <span className="fw-bold">Invoice No : </span>
                  <span className="font-size-sm">
                    {orderDetails[0]?.invoiceNo}
                  </span>
                </div>
              )}
            </div>

            <Tab.Container
              activeKey={activeTab}
              onSelect={(selectedTab) => setActiveTab(selectedTab)}
            >
              <Row className="d-flex justify-content-between align-items-center text-center mt-3">
                <Col>
                  <Nav className=" nav-tabs gap-2">
                    <Nav.Item
                      style={{
                        backgroundColor:
                          activeTab === 'currentStatus'
                            ? '#2E3650;'
                            : '#FFFFFF',
                        color:
                          activeTab === 'currentStatus'
                            ? '#FFFFFF'
                            : '#2E3650;',
                        borderRadius: '4px',
                        width: '180px',
                      }}
                    >
                      <Nav.Link
                        eventKey="currentStatus"
                        className="mb-sm-3 mb-md-0"
                      >
                        {t('Current Status')}
                      </Nav.Link>
                    </Nav.Item>
                    {isAdmin && (
                      <Nav.Item
                        style={{
                          backgroundColor:
                            activeTab === 'rejectionHistory'
                              ? '#2E3650;'
                              : '#FFFFFF',
                          color:
                            activeTab === 'rejectionHistory'
                              ? '#FFFFFF'
                              : '#2E3650;',
                          borderRadius: '4px',
                          width: '180px',
                        }}
                      >
                        <Nav.Link
                          eventKey="rejectionHistory"
                          className="mb-sm-3 mb-md-0"
                        >
                          {t('Rejection History')}
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </Col>
                <Col className="d-flex justify-content-end text-center align-content-center align-items-center gap-4 mt-2">
                  <div
                    style={{
                      backgroundColor: '#FFFFFF',
                      color: '#F4921B',
                      height: '40px',
                      width: '158px',
                      fontWeight: '500',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                    className=" text-uppercase font-size-sm"
                  >
                    <span>{`${currStatus ? currStatus : 'currStatus'}`}</span>
                  </div>
                  {currStatus === orderStatus.assigned ||
                  currStatus === orderStatus.accepted ? (
                    <span className="fs-4" style={{ width: '158px' }}>
                      {time?.mins > 0 && (
                        <CountdownTimer
                          hours={time?.hours}
                          mins={time?.mins}
                          callback={() =>
                            navigate(Routes.OMS.Orders.Assigned.path)
                          }
                        />
                      )}
                    </span>
                  ) : (
                    (currStatus === orderStatus?.invoiced ||
                      currStatus === orderStatus?.readyForPickup) &&
                    showShippingLabelDownload(orderDetails[0]) && (
                      <ButtonGroup className="gap-2">
                        <Button
                          variant="white"
                          onClick={() => handleClick('ewaybill')}
                          style={{
                            height: '40px',
                            width: '158px',
                            fontWeight: '500',
                            borderRadius: 0,
                          }}
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faReceipt} />
                          &nbsp;&nbsp; ewaybill
                        </Button>
                        <Button
                          variant="white"
                          onClick={() => handleClick('invoice')}
                          style={{
                            height: '40px',
                            width: '158px',
                            fontWeight: '500',
                            borderRadius: 0,
                          }}
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faFileInvoice} />
                          &nbsp;&nbsp; Invoice
                        </Button>
                        <Button
                          variant={
                            orderDetails[0]?.isShippingLabelDownloaded
                              ? 'white'
                              : 'primary'
                          }
                          onClick={handleClick}
                          style={{
                            height: '40px',
                            width: '158px',
                            fontWeight: '500',
                            borderRadius: 0,
                          }}
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faBox} />
                          &nbsp;&nbsp; shipping label
                        </Button>
                      </ButtonGroup>
                    )
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey="currentStatus" className="py-4">
                      <Card className=" ps-4">
                        <Timeline
                          events={orderDetails[0]?.actionLog}
                          rejectedHistory={
                            [
                              'On Hold',
                              'Rejected',
                              'Auto Rejected',
                              'Cancelled',
                            ].indexOf(orderDetails[0]?.status) >= 0
                          }
                        />
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey="rejectionHistory" className="py-4">
                      <Card className="ps-4">
                        <Timeline
                          events={
                            orderDetails[0]?.rejectionHistory ??
                            orderDetails[0]?.actionLog
                          }
                          rejectedHistory={true}
                        />
                      </Card>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
            <Row>
              <Col>
                <Card
                  className="p-2"
                  style={{ height: 'fit-content', minHeight: '150px' }}
                >
                  <div className="d-flex justify-content-between">
                    <Card.Subtitle className="pt-2 ps-2">
                      CUSTOMER DETAILS
                    </Card.Subtitle>
                    <div
                      className="bg-light rounded-circle p-2 d-flex justify-content-center align-content-center"
                      style={{ width: 33, height: 33 }}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </div>
                  <Card.Body className="pt-1">
                    <span className="fw-bold">FirstName : </span>
                    {orderDetails[0]?.customer?.firstName || 'firstname'}
                    <br />
                    <span className="fw-bold">MobileNumber : </span>
                    {orderDetails[0]?.customer?.mobileNumber || 'mobilenumber'}
                    <br />
                    <span className="fw-bold">Email : </span>{' '}
                    {orderDetails[0]?.customer?.email || '*****@gmail.com'}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  className="p-2"
                  style={{ height: 'fit-content', minHeight: '150px' }}
                >
                  <div className="d-flex justify-content-between">
                    <Card.Subtitle className="pt-2 ps-2">
                      BILLING DETAILS
                    </Card.Subtitle>
                    <div
                      className="bg-light rounded-circle p-2 d-flex justify-content-center align-content-center"
                      style={{ width: 33, height: 33 }}
                    >
                      <FontAwesomeIcon icon={faRectangleList} />
                    </div>
                  </div>
                  <Card.Body className="pt-1">
                    <span className="fw-bold">Name : </span>
                    {orderDetails[0]?.shippingAddress?.billingName ||
                      'billingName'}
                    <br />
                    <span className="fw-bold">MobileNumber : </span>{' '}
                    {orderDetails[0]?.shippingAddress?.billingMobileNumber ||
                      'billingMobileNumber'}
                    <br />
                    <span className="fw-bold">Address : </span>
                    {(orderDetails[0]?.shippingAddress &&
                      orderDetails[0]?.shippingAddress?.billingAddress1 +
                        ', ' +
                        orderDetails[0]?.shippingAddress?.billingCity +
                        ', ' +
                        orderDetails[0]?.shippingAddress?.billingState +
                        ', ' +
                        orderDetails[0]?.shippingAddress?.billingPincode) ||
                      'billingAddress'}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  className="p-2"
                  style={{ height: 'fit-content', minHeight: '150px' }}
                >
                  <div className="d-flex justify-content-between">
                    <Card.Subtitle className="pt-2 ps-2">
                      SHIPPING DETAILS
                    </Card.Subtitle>
                    <div
                      className="bg-light rounded-circle p-2 d-flex justify-content-center align-content-center"
                      style={{ width: 33, height: 33 }}
                    >
                      <FontAwesomeIcon icon={faTruckFast} />
                    </div>
                  </div>
                  <Card.Body className="pt-1">
                    <span className="fw-bold">Name : </span>
                    {orderDetails[0]?.shippingAddress?.shippingName ||
                      'shippingName'}
                    <br />
                    <span className="fw-bold">MobileNumber : </span>{' '}
                    {orderDetails[0]?.shippingAddress?.shippingMobileNumber ||
                      'shippingMobileNumber'}
                    <br />
                    <span className="fw-bold">Address : </span>
                    {(orderDetails[0]?.shippingAddress &&
                      orderDetails[0]?.shippingAddress?.shippingAddress1 +
                        ', ' +
                        orderDetails[0]?.shippingAddress?.shippingCity +
                        ', ' +
                        orderDetails[0]?.shippingAddress?.shippingState +
                        ', ' +
                        orderDetails[0]?.shippingAddress?.shippingPincode) ||
                      'shippingAddress'}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  className="p-2"
                  style={{
                    height: 'fit-content',
                    minHeight: '150px',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(true);
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Card.Subtitle className="pt-2 ps-2">
                      PAYMENT INFO
                    </Card.Subtitle>
                    <div
                      className="bg-light rounded-circle p-2 d-flex justify-content-center align-content-center"
                      style={{ width: 33, height: 33 }}
                    >
                      <FontAwesomeIcon icon={faCreditCard} />
                    </div>
                  </div>
                  <Card.Body className="pt-1">
                    <span className="fw-bold">Method : </span>{' '}
                    {orderDetails[0]?.payment?.[0]?.method || 'method'}
                    <br />
                    <span className="fw-bold">Mode : </span>{' '}
                    {orderDetails[0]?.payment?.[0]?.paymentMode || 'mode'}
                    <br />
                    <span className="fw-bold">Amount : </span>{' '}
                    {orderDetails[0]?.payment?.[0]?.billAmount || 'amount'}
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Card className="p-2 mt-2">
              <Card.Title>Items Ordered</Card.Title>
              <TableOnly
                data={orderDetails[0]?.items}
                isFooterRequired={false}
                columns={orderDetailsColumns()}
              />
            </Card>

            {orderDetails[0]?.status == orderStatus.delivered &&
              orderDetails[0]?.podImages?.length >= 0 && (
                <>
                  <Row className="m-2 p-2 bg-white d-flex align-content-center justify-content-between">
                    <Col>
                      <h5 className="m-0">Proof of Delivery</h5>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      {' '}
                      <Button onClick={handleDownload}>Download</Button>
                    </Col>
                  </Row>
                  <Row className="m-1 p-2 bg-white">
                    {[
                      ...orderDetails[0]?.podImages,
                      orderDetails[0]?.customerSignature,
                    ]?.map((each) => {
                      return (
                        <div
                          style={{
                            width: '200px',
                            height: '190px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            handleDownload(each);
                          }}
                        >
                          <img
                            style={{ width: '200px', height: '190px' }}
                            src={each}
                          ></img>
                        </div>
                      );
                    })}
                  </Row>
                </>
              )}

            {orderDetails[0].status == orderStatus.accepted &&
              orderDetails[0]?.podImages?.length >= 0 && (
                <>
                  <Row className="m-2 p-2 bg-white d-flex align-content-center">
                    <Col>
                      <h5 className="m-0">Proof of Delivery</h5>
                    </Col>
                  </Row>
                  <Row className="m-1 p-2 bg-white">
                    {orderDetails[0]?.podImages.map((each) => {
                      return (
                        <div
                          style={{
                            width: '200px',
                            height: '190px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <img src={each}></img>
                        </div>
                      );
                    })}
                  </Row>
                </>
              )}

            {orderDetails[0]?.status == orderStatus.completed &&
              orderDetails[0]?.isCustomerPickup &&
              orderDetails[0]?.customerPickUpImages?.length > 0 && (
                <>
                  <Row className="m-2 p-2 bg-white d-flex align-content-center">
                    <Col>
                      <h5 className="m-0">Proof of Customer PickUp</h5>
                    </Col>
                  </Row>
                  <Row className="m-1 p-2 bg-white">
                    {orderDetails[0]?.customerPickUpImages?.map((each) => {
                      return (
                        <div
                          style={{
                            width: '200px',
                            height: '190px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <img src={each}></img>
                        </div>
                      );
                    })}
                  </Row>
                </>
              )}
          </>
        )}
      </>
      <PaymentDetailsModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        data={orderDetails[0]}
      />
    </>
  );
};

export default OrderDetails;
