import {
  faHome,
  faUserCircle,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Button,
  Col,
  Form,
  Row,
  InputGroup,
} from '@themesberg/react-bootstrap';
import Datetime from 'react-datetime';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EditableDropDown from '../../../components/common/EditableDropdown';
import validate from '../validate/validate.js';
import { useDispatch } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner.js';
import { RadioDiv, StyledDiv, ErrorMessage } from './StyledComponents.js';
import { returnUserReadableDate } from '../../../utils/datetime.js';
import useFetchProducts from '../custom-hooks/useFetchProducts.js';

const FormComponent = ({
  schema,
  savefunction,
  editData,
  formProps,
  setFormProps,
  CustomComponent,
}) => {
  const { t } = useTranslation();

  const defaultValues = editData || {};
  if (!editData) {
    schema.flat().forEach((field) => {
      if (field.type === 'checkbox') {
        defaultValues[field.id] = new Array(field.options.length).fill(false);
      } else {
        if (field.options && field.type !== 'switch') {
          field.options.length === 1 &&
            (defaultValues[field.id] = [field.options[0].value]);
        } else {
          if (field.type === 'switch') {
            defaultValues[field.id] = false;
          } else {
            defaultValues[field.id] = '';
          }
        }
      }
    });
  }
  const [updatedSchema, setUpdatedSchema] = useState(schema);
  const [formInput, setFormInput] = useState(defaultValues);
  const [formErrors, setFormErrors] = useState({});
  useFetchProducts(
    formInput,
    setFormInput,
    updatedSchema,
    setUpdatedSchema,
    schema
  );

  const handleChange = (e, field, index) => {
    if (field.type === 'checkbox') {
      const { checked } = e.target;
      setFormInput((prev) => ({
        ...prev,
        [field.id]: [
          ...prev[field.id].map((v, i) => (i === index ? checked : v)),
        ],
      }));
    } else if (field.type === 'select' && field.isMultiSelect) {
      setFormInput((prev) => ({
        ...prev,
        [field.id]: e.map(({ value }) => value),
      }));
    } else if (field.type === 'select') {
      setFormInput((prev) => ({
        ...prev,
        [field.id]: [e.value],
      }));
    } else if (field.type === 'switch') {
      const { checked } = e.target;
      setFormInput((prev) => ({
        ...prev,
        [field.id]: checked,
      }));
    } else if (field.type === 'time') {
      const { value } = e.target;
      setFormInput((prev) => ({
        ...prev,
        [field.id]: value,
      }));
    } else {
      const value = e.target.value;
      setFormInput((prev) => ({
        ...prev,
        [field.id]: value,
      }));
    }

    if (formErrors[field.id]) {
      setFormErrors((prev) => ({ ...prev, [field.id]: '' }));
    }
    if (formProps?.hasOwnProperty(field.id)) {
      setFormProps((prev) => {
        const newProps = {
          ...prev,
          [field.id]: e.value ? [e.value] : e.target?.checked,
        };
        if (e.value === 'Sell-in') {
          newProps.cappingType = [];
          setFormInput((prev) => ({
            ...prev,
            cappingType: [],
          }));
        }
        if (field.isMultiSelect) {
          newProps[field.id] = e[e.length - 1]?.value
            ? [...prev[field.id], e[e.length - 1].value]
            : [];
        }
        return newProps;
      });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // let { undefined, ...finalData } = formInput;
    // const { result, reject } = parseJSONInputs(finalData);
    // if (reject) {
    //   setFormErrors(reject);
    //   console.log(reject);
    //   return;
    // }
    // finalData = { ...result };
    let {
      undefined,
      tokenGeneration,
      tracking,
      awbGeneration,
      ndr,
      serviceability,
      ...finalData
    } = formInput;

    if (tokenGeneration || tracking || awbGeneration || ndr || serviceability) {
      let keysToParse = [
        'tokenGeneration',
        'tracking',
        'awbGeneration',
        'ndr',
        'serviceability',
      ];

      for (let key of keysToParse) {
        try {
          finalData[key] = JSON.parse(formInput[key]);
        } catch (error) {
          setFormErrors({ [key]: 'Expected a JSON object' });
          return;
        }
      }
    }

    const error = validate(schema, finalData);
    const handleMessage = (errorMessage) => {
      if (typeof errorMessage === 'object' && errorMessage !== null) {
        setFormErrors(errorMessage);
      } else if (!errorMessage) {
        setFormErrors({});
        if (!editData) {
          setFormInput(defaultValues);
        }
      }
    };
    if (Object.keys(error).length > 0) {
      setFormErrors(error);
    } else {
      savefunction(finalData, handleMessage);
    }
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    setFormInput(defaultValues);
  };

  useEffect(() => {
    setFormInput(defaultValues);
  }, []);
  return (
    <div className="mt-1">
      <Row>
        <Form.Group>
          <StyledDiv>
            {updatedSchema?.map((fields, index) => (
              <Col key={index}>
                {fields.map((field, index) => (
                  <Form.Group className="mb-3" key={index}>
                    <div key={index}>
                      {!['button', 'header'].includes(field.type) && (
                        <Form.Label>{field.title}</Form.Label>
                      )}
                      {field.type === 'header' ? (
                        <Alert variant="info">
                          <FontAwesomeIcon
                            icon={
                              field.title !== 'Login Info'
                                ? faHome
                                : faUserCircle
                            }
                          />
                          &nbsp;&nbsp;{t(`${field.title}`)}
                        </Alert>
                      ) : field.type === 'select' ? (
                        <Form.Group>
                          <EditableDropDown
                            id={field.id}
                            isMultiSelect={field.isMultiSelect}
                            onChange={(e) => handleChange(e, field)}
                            value={formInput[field.id] || []}
                            disabled={field.disabled}
                            options={field.options}
                            required={field.required}
                            placeholder={field.placeholder}
                            isInvalid={formErrors[field.id]}
                          ></EditableDropDown>
                          <ErrorMessage>{t(formErrors[field.id])}</ErrorMessage>
                        </Form.Group>
                      ) : field.type === 'checkbox' ? (
                        <Form.Group>
                          <label>
                            {field.options.map((option, index) => (
                              <span key={index}>
                                <Form.Control
                                  type="checkbox"
                                  id={field.id}
                                  name={field.title}
                                  value={option}
                                  checked={
                                    formInput[field.id]
                                      ? formInput[field.id[field.option]]
                                      : false
                                  }
                                  onChange={(e) =>
                                    handleChange(e, field, index)
                                  }
                                  isInvalid={formErrors[field.id]}
                                />
                                {option}
                              </span>
                            ))}
                          </label>
                          <Form.Control.Feedback type="invalid">
                            {t(formErrors[field.id])}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) : field.type === 'switch' ? (
                        <Form.Group>
                          <label>
                            <Row>
                              <Col>
                                <Form.Check
                                  type="switch"
                                  id={`${field.id}`}
                                  name={field.title}
                                  value={formInput[field.id]}
                                  disabled={field.disabled}
                                  checked={formInput[field.id]}
                                  onChange={(e) => handleChange(e, field)}
                                  isInvalid={formErrors[field.id]}
                                />
                                <span
                                  type="invalid"
                                  style={{
                                    width: '100%',
                                    marginTop: '0.25rem',
                                    fontSize: '0.875em',
                                    color: '#FA5252',
                                  }}
                                >
                                  {t(formErrors[field.title])}
                                </span>
                              </Col>
                              <Col>
                                <p>
                                  {formInput[field.id]
                                    ? field?.options[0]?.label
                                    : field?.options[1]?.label}
                                </p>
                              </Col>
                            </Row>
                          </label>
                          <Form.Control.Feedback type="invalid">
                            {t(formErrors[field.title])}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) : field.type === 'radio' ? (
                        <Form.Group>
                          <RadioDiv>
                            {field.options.map((option, index) => (
                              <label key={index}>
                                <Form.Control
                                  type="radio"
                                  id={option}
                                  name={field.title}
                                  value={option}
                                  checked={formInput[field.title] === option}
                                  onChange={(e) => handleChange(e, field)}
                                  isInvalid={formErrors[field.id]}
                                />
                                {option}
                              </label>
                            ))}
                          </RadioDiv>
                          <Form.Control.Feedback type="invalid">
                            {t(formErrors[field.id])}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) : field.type === 'textarea' ? (
                        <Form.Group>
                          <Form.Control
                            id={field.id}
                            name={field.title}
                            value={formInput[field.id]}
                            disabled={field.disabled}
                            onChange={(e) => handleChange(e, field)}
                            minLength={field.minLength}
                            maxLength={field.maxLength}
                            rows={field.rows}
                            as="textarea"
                            isInvalid={formErrors[field.id]}
                          />
                          <Form.Control.Feedback type="invalid">
                            {t(formErrors[field.id])}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) : field.type === 'button' ? (
                        <Button
                          style={{
                            width: '98%',
                            marginTop: field.title !== 'Cancel' && '2rem',
                          }}
                          className="mx-2"
                          variant={
                            field.title !== 'Cancel' ? 'primary' : 'outline'
                          }
                          onClick={
                            field.title !== 'Cancel'
                              ? handleFormSubmit
                              : handleCancelClick
                          }
                        >
                          {t(`${field.title}`)}
                        </Button>
                      ) : field.type === 'date' ? (
                        <Datetime
                          timeFormat={false}
                          closeOnSelect={true}
                          onChange={(value) => {
                            setFormInput((prev) => ({
                              ...prev,
                              [field.id]: new Date(value),
                            }));
                          }}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon
                                  icon={faCalendarAlt}
                                  className="icon icon-xs"
                                />
                              </InputGroup.Text>
                              <Form.Control
                                isInvalid={formErrors[field.id]}
                                required
                                type="text"
                                value={returnUserReadableDate(
                                  formInput[field.id]
                                )}
                                placeholder="Select date: dd/mm/yyyy"
                                onFocus={openCalendar}
                                disabled={field.disabled}
                              />
                              {formErrors?.[field.id]?.length > 0 && (
                                <Form.Control.Feedback type="invalid">
                                  {t(formErrors[field.id])}
                                </Form.Control.Feedback>
                              )}
                            </InputGroup>
                          )}
                        />
                      ) : (
                        <Form.Group>
                          <Form.Control
                            type={field.type}
                            id={field.id}
                            name={field.title}
                            value={formInput[field.id]}
                            disabled={field.disabled}
                            onChange={(e) => handleChange(e, field)}
                            minLength={field.minLength}
                            maxLength={field.maxLength}
                            min={field.min}
                            max={field.max}
                            isInvalid={formErrors[field.id]}
                          />
                          <Form.Control.Feedback type="invalid">
                            {t(formErrors[field.id])}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    </div>
                  </Form.Group>
                ))}
              </Col>
            ))}
            {formProps?.uploadExcel && <Col>{CustomComponent}</Col>}
          </StyledDiv>
        </Form.Group>
      </Row>
    </div>
  );
};

export default FormComponent;
