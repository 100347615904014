import React, { useState } from "react";
import { Modal, Button, Form } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import { generateRandomId } from "../../../../utils/string";
import DropdownComponent from "../../../../components/common/Dropdown";

const InventoryModal = (props) => {
  const { t } = useTranslation();
  const { showModal, closeModal, handleSubmit, modalTitile, modalData, type } =
    props;
  const [modalValue, setModalValue] = useState({
    [type]: null,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    // const fieldName = Object.keys(modalValue)[0];
    setModalValue({ [type]: parseInt(event?.target?.value) });
  };

  const handleSubmitClick = () => {
    // const fieldName = Object.keys(modalValue)[0];
    let value = modalValue[type];
    if (value !== 0 && !modalValue[type]) {
      setErrors({ [type]: "please enter quantity" });
      return;
    }
    if (
      type !== "safetyStockLimit" &&
      (value < 0 || value > modalData.totalStock)
    ) {
      setErrors({
        [type]: "please enter quantity less than totalStock",
      });
      return;
    }

    setErrors({});
    handleSubmit(modalValue);
    setModalValue({ [type]: null });
  };
  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title className="h6">{t(modalTitile)}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <Form.Label className="mt-3">{t("Enter quantity")}</Form.Label>
        <Form.Control
          required
          type="number"
          isInvalid={!!errors[type]}
          onChange={handleChange}
        />
        {errors[type] && (
          <Form.Control.Feedback type="invalid">
            {errors[type]}
          </Form.Control.Feedback>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleSubmitClick}
          disabled={Object.keys(modalValue).length <= 0}
        >
          {t("Submit")}
        </Button>
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={closeModal}
        >
          {t("Cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default InventoryModal;
