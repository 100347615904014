import { faArrowUpFromBracket, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@themesberg/react-bootstrap";
import React from "react";
import { exportToExcel } from 'react-json-to-excel';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateToastInfo } from "../../../actions/settings";
import { convertdownloadDataToFlatJSON, ignoreColumnsInTotalForDeliveryData, returnDownloadableData, returnTotalRowFromData } from "../../../utils/excel";

const ExcelDownloadReport = (props) => {
    const {
        data,
        fileName,
        addTotal = false,
        isAttendance = false
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <Button
            onClick={() => {
                const flatJSONData = convertdownloadDataToFlatJSON(data);
                const downloadableData = returnDownloadableData(flatJSONData);
                if (addTotal) {
                    const totalRow = returnTotalRowFromData(downloadableData, ignoreColumnsInTotalForDeliveryData, isAttendance);
                    totalRow[Object.keys(downloadableData[0])[0]] = "Total";
                    downloadableData.push(totalRow)
                }
                exportToExcel(downloadableData, fileName)
                dispatch(updateToastInfo({
                    show: true,
                    type: "success",
                    title: t("File Downloaded"),
                    message: t("Please check your downloads for file")
                }))
            }}
            variant="white"
            size="md"
            className="btn-export"
            disabled={data?.length === 0}>
                <FontAwesomeIcon icon={faArrowUpFromBracket} />&nbsp;&nbsp;{t("Export")}

        </Button>

    );
}
export default ExcelDownloadReport