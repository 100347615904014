import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OrderListPage from '../../../../components/common/OrderListPage';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import { updateSpinnerState } from '../../../../actions/spinner';
import { apiCall } from '../../../../middlewares/api';
import { updateToastInfo } from '../../../../actions/settings';
import { returnOrderStatus } from '../../../../constants/orderStatus';
import ConfirmationModal from '../../../../components/common/confirmationModal';
import { returnOrderColumns } from './utils';
import { apiCallConsts } from '../../../../constants/apiCallConsts';
import { useNavigate } from 'react-router-dom';
import ReplacementModal from '../../orders/components/ReplacementModal';

const OrderReturnPage = (props) => {
  const {
    type,
    showFilter,
    bulkActionOptions,
    hideBulkActions,
    defaultConfirmationProps,
    confirmationModalProps,
    setConfirmationModalProps,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateMode, setUpdateMode] = useState('');
  const [returnsData, setReturnData] = useState([]);
  const storeName = useSelector(
    (state) => state.user.preferences.storeName || ''
  );
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const breadCrumbItems = arrayToBreadCrumbs([[t('Returns')], [t(type)]]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showReplacementModal, setShowReplacementModal] = useState(false);
  const navigate = useNavigate();

  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/order?storeCode=${storeName}&status=${type}&serviceType=${returnOrderStatus.SERVICETYPE}`
      );
      setReturnData(res);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('Return Orders fetched successfully'),
        })
      );
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to fetch Orders'),
          message: t(error.message),
        })
      );
    }
  };

  const onRefreshClick = () => {
    fetchOrders();
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedOrders((prev) => {
        const selectedItem = returnsData.find(
          (item) => item.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedOrders((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setReturnData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  useEffect(() => {
    if (isAdmin) {
      fetchOrders();
    }
    if (storeName) {
      fetchOrders();
    }
  }, [isAdmin, storeName]);

  const updateOrders = async (data) => {
    await apiCall(
      apiCallConsts.PUT_METHOD,
      apiCallConsts.ORDER_UPDATE_URL,
      data
    );
  };

  const handleSelectedRowsUpdate = (actionId) => {
    if (
      selectedOrders.length > 1 &&
      (actionId === 'qcpass' || actionId === 'refunded')
    ) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Max 1 order to update'),
          message: t('Please select maximum of 1 order'),
        })
      );
      return;
    }
    const errorDisplayer = () => {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          message: t('Please select correct option for the status'),
        })
      );
    };
    switch (actionId) {
      case 'accept': {
        setUpdateMode(returnOrderStatus.ACCEPTED);
        setConfirmationModalProps(defaultConfirmationProps['qcCheck']);
        handleShowModal();
        break;
      }
      case 'picked': {
        setUpdateMode(returnOrderStatus.PICKED);
        handleShowModal();
        break;
      }
      case 'received': {
        setUpdateMode(returnOrderStatus.RECEIVED);
        handleShowModal();
        break;
      }
      case 'qcpass': {
        setUpdateMode(returnOrderStatus.QCPASSED);
        setConfirmationModalProps(defaultConfirmationProps['qcPassed']);
        handleShowModal();
        break;
      }
      case 'refundInitiated': {
        if (selectedOrders[0].isReplacement) {
          errorDisplayer();
          break;
        }
        setUpdateMode(returnOrderStatus.REFUND_INITIATED);
        setConfirmationModalProps(defaultConfirmationProps['refundInitiated']);
        handleShowModal();
        break;
      }
      case 'refunded': {
        setUpdateMode(returnOrderStatus.REFUNDED);
        setConfirmationModalProps(defaultConfirmationProps['refunded']);
        handleShowModal();
        break;
      }
      case 'replacementInitiated': {
        if (!selectedOrders[0].isReplacement) {
          errorDisplayer();
          break;
        }
        setUpdateMode(returnOrderStatus.REPLACEMENT_INITIATED);
        setShowReplacementModal(true);
        break;
      }
      case 'earlyRefund': {
        setUpdateMode('earlyRefund');
        setConfirmationModalProps(defaultConfirmationProps['earlyRefund']);
        handleShowModal();
        break;
      }
      default:
        break;
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      // console.log(data);
      await updateOrders(data);
      await fetchOrders();
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: ``,
          message: 'Update Successful!',
        })
      );
      setShowModal(false);
      setShowReplacementModal(false);
      setSelectedOrders([]);
      defaultConfirmationProps && setConfirmationModalProps([]);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Cannot be updated!',
          message: e.message,
        })
      );
    }
  };

  const handleModalOnConfirmClick = ({ data }) => {
    if (updateMode === returnOrderStatus.QCPASSED) {
      const result =
        selectedOrders[0].serialNo === confirmationModalProps[0].value;
      if (!result) {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('failed to change status'),
            message: t('Please enter correct serialNo!'),
          })
        );
        return;
      }
    }

    let selectedOrdersData = {
      id: selectedOrders.map((item) => item.objectId),
      status: updateMode,
      username: userName,
      serviceType: returnOrderStatus.SERVICETYPE,
    };
    const [date, amount, transactionNo, mode, remarks] =
      confirmationModalProps || [];
    switch (updateMode) {
      case returnOrderStatus.ACCEPTED:
        selectedOrdersData.is3PLQcCheckNeeded = confirmationModalProps[0].value;
        break;
      case returnOrderStatus.QCPASSED:
        selectedOrdersData.serialNo = confirmationModalProps[0].value;
        break;
      case returnOrderStatus.REFUND_INITIATED:
        selectedOrdersData.SRTNo = confirmationModalProps[0].value;
        break;
      case returnOrderStatus.REPLACEMENT_INITIATED:
        console.log(data);
        selectedOrdersData.storeCode = data.storeCode;
        break;
      case returnOrderStatus.REFUNDED:
        selectedOrdersData.date = date.value;
        selectedOrdersData.amount = amount.value;
        selectedOrdersData.transactionNo = transactionNo.value;
        selectedOrdersData.mode = mode.value;
        selectedOrdersData.remarks = remarks.value;
        break;
      case 'earlyRefund':
        selectedOrdersData.date = date.value;
        selectedOrdersData.amount = amount.value;
        selectedOrdersData.transactionNo = transactionNo.value;
        selectedOrdersData.mode = mode.value;
        selectedOrdersData.remarks = remarks.value;
        selectedOrdersData.isEarlyRefund = true;
        delete selectedOrdersData.status;
      default:
        break;
    }
    handleOnConfirmClick(selectedOrdersData);
  };

  return (
    <>
      <OrderListPage
        pageType={`${type}orders`}
        showDateFilters={showFilter}
        tableData={returnsData}
        tableColumns={returnOrderColumns(
          onRowSelect,
          navigate,
          bulkActionOptions,
          isAdmin,
          type
        )}
        handleRefresh={onRefreshClick}
        pageTitle={t(`${type} Orders`)}
        breadCrumbItems={breadCrumbItems}
        // pageSubTitle={t(`All the ${type} Orders`)}
        enableBulkActions={selectedOrders.length > 0}
        hideBulkActions={hideBulkActions}
        bulkActionOptions={bulkActionOptions}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />

      <ConfirmationModal
        showModal={showModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowModal(false);
          defaultConfirmationProps && setConfirmationModalProps([]);
        }}
        modalBody={t(
          `Are you sure you want to change the status to ${updateMode}?`
        )}
        modalTitle={t(`${updateMode} Order(s)`)}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
      <ReplacementModal
        showModal={showReplacementModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowReplacementModal(false);
        }}
        selectedOrders={selectedOrders}
        type={'store'}
        modalBody={t(`Are you sure you want to raise a Replacement Request?`)}
      />
    </>
  );
};

export default OrderReturnPage;
