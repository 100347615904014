import { Form } from '@themesberg/react-bootstrap';
import EditableDropDown from '../../components/common/EditableDropdown';
import { ErrorMessage } from '../../pages/scheme-management/Components/StyledComponents';
import { getTableRowCheckBox, returnCustomerAddressString } from '../table';
import React from 'react';
import { generateRandomId } from '../string';
import { arrayToDropdownOptions } from './arrayToDropdownOptions';

export const bulkAddSchemeColumns = (categories, handleChange) => {
  const columns = [
    {
      dataField: 'category',
      text: 'Category',
      formatter: (cell, row) => {
        if (!categories) {
          return <span>{cell}</span>;
        }
        return (
          <Form.Group>
            <EditableDropDown
              id={generateRandomId()}
              onChange={(e) => handleChange(row, e.value)}
              value={row.category}
              options={arrayToDropdownOptions(categories)}
              required={true}
              placeholder={'Select a category'}
            />
            {row.error && <ErrorMessage>{row.error}</ErrorMessage>}
          </Form.Group>
        );
      },
    },
    {
      dataField: 'subCategory',
      text: 'Sub-Category',
      formatter: (cell, row) => {
        return row.subCategory || row.productSubCategory;
      },
    },
    {
      dataField: 'productName',
      text: 'Product Name',
      formatter: (cell, row) => {
        return returnCustomerAddressString(cell);
      },
    },
    {
      dataField: 'vanNo',
      text: 'Van No',
    },
    {
      dataField: 'productCode',
      text: 'Product Code',
    },
    {
      dataField: 'valuationType',
      text: 'Valuation Type',
    },
    {
      dataField: 'payout',
      text: 'Payout',
    },
    {
      dataField: 'payoutBasis',
      text: 'Payout Basis',
    },
  ];

  return columns;
};

export const generateTableRow = (rowData, onRowSelect, onInputChange) => {
  return rowData.map((row) => (
    <tr key={row.objectId}>
      <td>{getTableRowCheckBox(onRowSelect, row.objectId, row.isSelected)}</td>
      <td>{returnCustomerAddressString(row.productName)}</td>
      <td>{row.vanNo}</td>
      <td>
        <EditableDropDown
          id={row.objectId}
          onChange={(e) => onInputChange(e, row, 'valuationType')}
          value={row.valuationType || []}
          disabled={!row.isSelected}
          options={[
            { label: 'Percentage', value: 'Percentage' },
            { label: 'Value', value: 'Value' },
          ]}
          required={true}
          placeholder={'Select a type'}
          isInvalid={row.errors?.valuationType}
        />
        <ErrorMessage>{row.errors?.valuationType}</ErrorMessage>
      </td>
      <td>
        <Form.Group>
          <Form.Control
            type="number"
            id={row.objectId}
            value={row.payout}
            onChange={(e) => onInputChange(e, row, 'payout')}
            isInvalid={Boolean(row.errors?.payout)}
            disabled={!row.isSelected}
          />
        </Form.Group>
        <ErrorMessage>{row.errors?.payout}</ErrorMessage>
      </td>
      <td>
        <EditableDropDown
          id={row.objectId}
          onChange={(e) => onInputChange(e, row, 'payoutBasis')}
          value={row.payoutBasis || []}
          disabled={!row.isSelected}
          options={[
            { label: 'DP', value: 'DP' },
            { label: 'DP(Basic)', value: 'DP(Basic)' },
            { label: 'Net of Margin', value: 'Net of Margin' },
            { label: 'NLC(Basic)', value: 'NLC(Basic)' },
          ]}
          required={true}
          placeholder={'Select a method'}
          isInvalid={Boolean(row.errors?.payoutBasis)}
        />
        <ErrorMessage>{row.errors?.payoutBasis}</ErrorMessage>
      </td>
    </tr>
  ));
};
