import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

export const customDropDownStyles = (width) => {
    return {
        control: (provided) => ({
            ...provided,
            borderRadius: '.5rem',
            width
        }),

        multiValue: (provided) => ({
            ...provided,
            borderRadius: '.5rem'
        }),

        multiValueRemove: (provided) => ({
            ...provided,
            borderRadius: '.5rem'
        }),

        placeholder: (provided) => ({
            ...provided,
            borderRadius: '.5rem',
        }),

        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return {
                ...provided,
                opacity,
                transition,
            };
        }
    }
};
const EditableDropDown = (props) => {
    const { t } = useTranslation();
    const {
        onChange,
        options, // should have a label and value field.
        placeholder = "Select an Option",
        isMultiSelect = false,
        disabled = false,
        width,
        value,
        valueKey = "value"
    } = props;
    let selectedValue = options?.filter(option => option[valueKey] === value);
    if (typeof value !== "string") {
        selectedValue = value?.map(item => ({
            label: item,
            value: item
        }))
    }
    return (
        <Select
            value={selectedValue}
            isDisabled={disabled}
            onChange={onChange}
            options={options}
            placeholder={t(placeholder)}
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: '#61DAFB',
                    primary: '#61DAFB',
                }
            })}
            styles={customDropDownStyles(width)}
            isMulti={isMultiSelect} />
    )
}
export default EditableDropDown