import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateRandomId } from '../../../../utils/string';
import EditableDropDown from '../../../../components/common/EditableDropdown';
import { returnWarehouseDropdownOptions } from '../../../../utils/warehouse';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../../actions/spinner';
import { apiCall } from '../../../../middlewares/api';
import { updateToastInfo } from '../../../../actions/settings';
export const ShowAssignModal = (props) => {
  const { t } = useTranslation();
  const { showModal, onConfirm, closeModal } = props;
  const [storeCode, setStoreCode] = useState([]);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const zone = useSelector((state) => state?.user?.preferences?.zone);

  useEffect(() => {
    const fetchStore = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const data = await apiCall(
          'get',
          `internal/store/all?region=${zone}`,
          {}
        );
        setOptions(returnWarehouseDropdownOptions(data, false));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('failed to fetch'),
            message: t(`${error.message}`),
          })
        );
      }
    };

    fetchStore();
  }, []);
  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title className="h6">{t('Assign a Store')}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <EditableDropDown
              id={'StoreName'}
              onChange={(e) => setStoreCode([e.value])}
              value={storeCode}
              options={options}
              placeholder={'Select a store to assign'}
            ></EditableDropDown>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => onConfirm(storeCode)}
          disabled={storeCode.length === 0}
        >
          {t('Confirm')}
        </Button>
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={closeModal}
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
